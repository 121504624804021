import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

import { Divider } from '@mui/material'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { TypographySecondaryBold14 } from 'ui/typography/TypographySecondaryBold14'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as FilterArray } from '../../assets/filter_array.svg'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographyPrimary14Bold } from '../../ui/typography/TypographyPrimary14Bold'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'

const GroupHeadQtyLegend = props => {
	const [fetchDayData, setFetchDayData] = useState(props.fetchData)
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (props.daysBetweenDates === 0) {
			setFetchDayData(
				props.fetchData
					.slice(1, -1)
					.filter(el => el.quantity !== el.prevQuantity)
			)
		} else {
			setFetchDayData(
				props.fetchData.filter(el => el.quantity !== el.prevQuantity)
			)
		}
	}, [props.dateRange, props.fetchData])

	const handleClick = () => {
		setOpen(prevState => !prevState)
	}
	const handleReverseArray = () => {
		const reversedArray = [...fetchDayData].reverse()
		setFetchDayData(reversedArray)
	}

	const getMonthName = month => {
		const monthNames = [
			'Января',
			'Февраля',
			'Марта',
			'Апреля',
			'Мая',
			'Июня',
			'Июля',
			'Августа',
			'Сентября',
			'Октября',
			'Ноября',
			'Декабря',
		]
		return monthNames[month]
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				marginTop: '26px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<TypographyPrimary14Bold onClick={handleClick}>
					История изменений
				</TypographyPrimary14Bold>
				<ArrowOpenDone
					onClick={handleClick}
					style={{
						width: '14px',
						transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
					}}
				/>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<Grid
					container
					sx={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}
				>
					<Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row' }}>
						<TypographySecondary>Дата и время</TypographySecondary>
						<FilterArray
							onClick={handleReverseArray}
							style={{ marginLeft: '10px' }}
						/>
					</Grid>
					<Grid item xs={3}>
						<TypographySecondary>Внёс изменения</TypographySecondary>
					</Grid>
					<Grid item xs={1}>
						<TypographySecondary>Было</TypographySecondary>
					</Grid>
					<Grid item xs={1}>
						<TypographySecondary>Стало</TypographySecondary>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>

				<Divider sx={{ marginTop: '8px' }} />
				<Box
					sx={{
						height: '220px',
						overflow: 'auto',

						'&::-webkit-scrollbar': {
							width: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DBDBDB',
							borderRadius: 12,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#5222D0',
							borderRadius: 12,
						},
					}}
				>
					{fetchDayData.length === 0 || !fetchDayData ? (
						<>
							<TypographySecondaryBold14
								sx={{ textAlign: 'center', marginTop: '100px' }}
							>
								Нет данных
							</TypographySecondaryBold14>
							<TypographySecondary14
								sx={{ textAlign: 'center', marginTop: '10px' }}
							>
								Нет данных, соответствующих заданным условиям.
							</TypographySecondary14>
						</>
					) : (
						fetchDayData?.map((el, index) => (
							<Grid
								container
								sx={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '16px',
									marginTop: '16px',
									alignItems: 'center',
								}}
								key={index}
							>
								<Grid item xs={2}>
									<TypographyPrimary>
										{`${moment(el.changed_dt).format('DD')} ${getMonthName(
											moment(el.changed_dt).month()
										)} ${moment(el.changed_dt).format('YYYY, HH:mm')}`}
									</TypographyPrimary>
								</Grid>
								<Grid item xs={3}>
									<TypographyPrimary>
										{el.actor !== null
											? el.actor.lastname + ' ' + el.actor.firstname
											: '-'}
									</TypographyPrimary>
								</Grid>
								<Grid item xs={1}>
									<TypographySecondary14>
										{Math.round(el.prevQuantity)}
									</TypographySecondary14>
								</Grid>
								<Grid item xs={1}>
									<TypographyPrimary>
										{Math.round(el.quantity)}
									</TypographyPrimary>
								</Grid>
								<Grid item xs={4}></Grid>
							</Grid>
						))
					)}
				</Box>
				<TypographySecondary>Всего: {fetchDayData.length}</TypographySecondary>
			</Collapse>
		</Box>
	)
}

export default GroupHeadQtyLegend
