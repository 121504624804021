import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import GroupSpecificData from './GroupSpecificData'

type GroupInfoProps = {
	reachable?: number
	unreachable?: number
	total_percent?: number
	barntitle: string
	rationtitle: string
	cattles_qty: number
	description?: string
	weight_per_head: number
	lastDistribution: number
	lastNudge: number
	noData: boolean
}

const GroupSpecificInfo = ({
	unreachable,
	reachable,
	total_percent,
	barntitle,
	rationtitle,
	cattles_qty,
	description,
	weight_per_head,
	lastDistribution,
	lastNudge,
	noData,
}: GroupInfoProps) => {
	return (
		<CardStandart
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '24px',
				height: '100%',
			}}
		>
			<TypographyHeader>Общая информация</TypographyHeader>
			<GroupSpecificData
				cattles_qty={cattles_qty}
				barntitle={barntitle}
				description={description}
				rationtitle={rationtitle}
				weight_per_head={weight_per_head}
				reachable={reachable}
				total_percent={total_percent}
				unreachable={unreachable}
				lastDistribution={lastDistribution}
				lastNudge={lastNudge}
				noData={noData}
			/>
		</CardStandart>
	)
}

export default GroupSpecificInfo
