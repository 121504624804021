import { ICameraListItem } from 'models/IFrameInfo'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
	cowChangeInFrame,
	fetchDailyStatistics,
	fetchDigitizatedCowInfo,
} from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'

const useDigitizationInfo = () => {
	const location = useLocation()
	const cameraInfo = location.state as ICameraListItem
	const initialData = {
		tagNumber: undefined,
		tagImage: undefined,
		images: undefined,
	}
	const [currentInfoAboutCow, setCurrentInfoAboutCow] = useState(initialData)
	const [errorMessages, setErrorMessages] = useState<string>('')
	const dispatch = useAppDispatch()

	const handleFiles = ({ key, value }) => {
		setCurrentInfoAboutCow(prevState => ({
			...prevState,
			[key]: value,
		}))
	}

	const { infoFromDigitizatedCow, dailyStatistics } = useAppSelector(
		state => state.cattlesSlice
	)
	const messages = useAppSelector(
		state => state.websocketReducer.messageForCattleInfoDigitization
	)

	useEffect(() => {
		if (messages !== null) {
			dispatch(cowChangeInFrame(messages))
		}
	}, [messages])

	useEffect(() => {
		dispatch(fetchDailyStatistics('all'))
		const fetchData = async () => {
			try {
				dispatch(fetchDigitizatedCowInfo(cameraInfo.id))
			} catch (error) {
				dispatch(
					notifyUser({
						title: 'Ошибка по камере',
						description: error.message,
						isError: true,
					})
				)
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		if (
			infoFromDigitizatedCow !== undefined &&
			Object.keys(infoFromDigitizatedCow).length > 0
		) {
			handleFiles({ key: 'images', value: infoFromDigitizatedCow.images })
			const findTagImage = infoFromDigitizatedCow.cow.images.find(
				el => el.angle === 'tag'
			)?.image
			if (findTagImage) {
				handleFiles({
					key: 'tagImage',
					value: findTagImage,
				})
				handleFiles({
					key: 'tagNumber',
					value: infoFromDigitizatedCow.cow.tag_id,
				})
			}
		} else {
			setCurrentInfoAboutCow(initialData)
		}
	}, [infoFromDigitizatedCow])

	useEffect(() => {
		const isEmptyInput =
			// currentInfoAboutCow.images?.length !== 0 &&
			currentInfoAboutCow.tagNumber === ''
		isEmptyInput && setErrorMessages('Обязательное поле')
	}, [currentInfoAboutCow])

	const findTagPictureInfo =
		infoFromDigitizatedCow &&
		infoFromDigitizatedCow?.cow?.images.find(el => el.angle === 'tag')
	const findTagNumberInfo =
		infoFromDigitizatedCow && infoFromDigitizatedCow?.cow?.tag_id

	const isIdentificatedCow: any =
		infoFromDigitizatedCow && findTagPictureInfo && findTagNumberInfo

	const isNeedSaveTagPicture = !findTagPictureInfo
		? true
		: JSON.stringify(findTagPictureInfo.image) !==
		  JSON.stringify(currentInfoAboutCow?.tagImage)

	const isNeedSaveTagNumber = !findTagNumberInfo
		? true
		: JSON.stringify(findTagNumberInfo?.toString()) !==
		  JSON.stringify(currentInfoAboutCow?.tagNumber?.toString())

	const isAvailableIdentified =
		dailyStatistics?.identified_count.value <
		dailyStatistics?.identified_count.out_of

	const isEnabledButton =
		currentInfoAboutCow?.images?.length > 0 &&
		currentInfoAboutCow?.tagNumber > 0 &&
		currentInfoAboutCow?.tagImage &&
		isAvailableIdentified

	return {
		currentInfoAboutCow,
		dailyStatistics,
		infoFromDigitizatedCow,
		errorMessages,
		setErrorMessages,
		cameraInfo,
		handleFiles,
		isAvailableIdentified,
		isIdentificatedCow,
		isNeedSaveTagPicture,
		isNeedSaveTagNumber,
		isEnabledButton,
	}
}

export default useDigitizationInfo
