import Box from '@mui/material/Box/Box'
import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import GroupHeadEditPopup from 'components/group/GroupHeadEditPopup'
import { NotificationsTypes } from 'models/notifications'
import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import CattlesApiService from 'services/cattlesApiServices'
import {
	notifyUser,
	setShowModalEditCattlesQty,
	setShowWeightNotif,
} from 'store/notificationsSlice'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import NotificationSide from '../components/notifications/NotificationSide'
import Notification from '../components/shared/Notification'
import useWebSocket from '../hook/useWebsocket'
import axAPI from '../http'
import { useAppDispatch, useAppSelector } from '../store/hooks'

const MainPage = () => {
	const [openNotifications, setOpenNotifications] = useState(false)
	const [countNotif, setCountNotif] = useState(0)
	const { userData } = useAppSelector(store => store.userReducer)
	const { apiToken } = useAppSelector(state => state.authReducer)
	const messages = useAppSelector(
		state => state.websocketReducer.messageForNotifications
	)
	const location = useLocation()
	const dispatch = useAppDispatch()
	const { isShowWeightNotif, isShowModalEditCattlesQty } = useAppSelector(
		store => store.notificationsSlice
	)
	useEffect(() => {
		CattlesApiService.getCheckChangedWeightCattlesQty().then(resonse => {
			if (resonse.status !== 200) {
				dispatch(setShowWeightNotif({ isShowWeightNotif: true }))
			}
		})
	}, [])
	useEffect(() => {
		isShowWeightNotif &&
			location.pathname.startsWith('/feed') &&
			dispatch(
				notifyUser({
					title: 'Актуализируйте информацию',
					description:
						'Обновите данные для корректного расчёта суточного объёма корма.',
					isWarning: true,
					type: NotificationsTypes.DailyChangedWeight,
				})
			)
	}, [location])
	useWebSocket(userData, apiToken)
	const notificationButtonRef = useRef(null)
	useEffect(() => {
		if (messages !== null) {
			setCountNotif(prevState => prevState + 1)
		}
	}, [messages])

	useEffect(() => {
		axAPI.get('notifications/count').then(response => {
			setCountNotif(response.data.new_count)
		})
	}, [])

	return (
		<>
			<Box sx={{ display: 'flex', position: 'relative' }}>
				<CssBaseline />
				<Sidebar
					setOpenNotifications={setOpenNotifications}
					countNotif={countNotif}
					openNotifications={openNotifications}
					notificationButtonRef={notificationButtonRef}
				/>
				<Header />
				<Box
					component='main'
					sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
				>
					<Toolbar />
					<GroupHeadEditPopup
						open={isShowModalEditCattlesQty}
						handleClose={() =>
							dispatch(
								setShowModalEditCattlesQty({ isShowModalEditCattlesQty: false })
							)
						}
					/>
					<Outlet />
				</Box>
			</Box>
			{!location.pathname.includes('fill') && (
				<NotificationSide
					openNotifications={openNotifications}
					setOpenNotifications={setOpenNotifications}
					countNotif={countNotif}
					setCountNotif={setCountNotif}
					notificationButtonRef={notificationButtonRef}
				/>
			)}
			<Notification />
		</>
	)
}

export default MainPage
