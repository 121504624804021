import { ReactComponent as NextIcon } from 'assets/arrow_close_logo.svg'
import { NavLink } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'

const LamenessTrim = () => {
	return (
		<NavLink to='/cattles/trim'>
			<CardStandart
				sx={{
					padding: '24px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<TypographyHeader>Обрезка копыт</TypographyHeader>
				<NextIcon
					stroke={COLORS.icon}
					style={{ transform: 'rotate(-90deg)' }}
				/>
			</CardStandart>
		</NavLink>
	)
}

export default LamenessTrim
