import { useEffect } from 'react'

import { Box, Typography } from '@mui/material'
import { ReactComponent as CowCounterLogo } from 'assets/cow_counter_logo.svg'
import { ReactComponent as CattlesEditLogo } from 'assets/ration_edit_logo.svg'
import { fetchCattlesQuantity } from 'store/counterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowModalEditCattlesQty } from 'store/notificationsSlice'
import { COLORS } from 'styles/colors'

const CowCounter = props => {
	const dispatch = useAppDispatch()
	const { cattles_qty } = useAppSelector(state => state.counterReducer)
	const { permissions } = useAppSelector(state => state.authReducer)
	const { isShowModalEditCattlesQty } = useAppSelector(
		state => state.notificationsSlice
	)
	useEffect(() => {
		dispatch(fetchCattlesQuantity())
	}, [isShowModalEditCattlesQty])

	return (
		<Box sx={{ display: 'flex', pl: '16px' }}>
			<CowCounterLogo />
			<Typography
				sx={{
					fontSize: 16,
					fontWeight: 600,
					paddingBottom: '8px',
					paddingLeft: '4px',
					paddingRight: '8px',
				}}
			>
				Всего голов {cattles_qty}
			</Typography>
			{permissions.can_edit_cattle_quantity_main && (
				<CattlesEditLogo
					fill={COLORS.icon}
					style={{ width: '16px', cursor: 'pointer' }}
					onClick={() =>
						dispatch(
							setShowModalEditCattlesQty({ isShowModalEditCattlesQty: true })
						)
					}
				/>
			)}
		</Box>
	)
}

export default CowCounter
