import { Box, Checkbox } from '@mui/material'
import { ReactComponent as HoofIcon } from 'assets/hoof-icon.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import InputWithoutRestrictions from 'components/shared/textFields/InputWithoutRestrictions'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { fetchDiagnosesList } from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { COLORS } from 'styles/colors'
import { TypographyHeader14 } from 'ui/typography/TypographyHeader14'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { ICheckupList, IDiagnosisBlock, PositionHoof } from '../models'

type DiagnosisBlockProps = {
	diagnosisInfo: IDiagnosisBlock[]
	setCheckupInfo: Dispatch<SetStateAction<ICheckupList>>
	disposeRecommended: boolean
}

const icon = <CheckBox />
const checkedIcon = (
	<CheckBoxActive lineColor={COLORS.health} bgColor={COLORS.health} />
)
const DiagnosticBlock = (props: DiagnosisBlockProps) => {
	const { diagnosisInfo, setCheckupInfo, disposeRecommended } = props
	const dispatch = useAppDispatch()
	const { diagnosesList, diagnosesListPending } = useAppSelector(
		state => state.cattlesSlice
	)

	useEffect(() => {
		if (diagnosesList.length === 0) {
			dispatch(fetchDiagnosesList())
		}
	}, [])
	const isFrontLeftCheckbox = diagnosisInfo.some(
		el => el.position === PositionHoof.Front_Left
	)

	const isRearLeftCheckbox = diagnosisInfo.some(
		el => el.position === PositionHoof.Rear_Left
	)

	const isFrontRightCheckbox = diagnosisInfo.some(
		el => el.position === PositionHoof.Front_Right
	)

	const isRearRightCheckbox = diagnosisInfo.some(
		el => el.position === PositionHoof.Rear_Right
	)
	const isEmptyDiagnoses = diagnosisInfo.length === 0

	const isError = isEmptyDiagnoses && disposeRecommended

	const changeInfo = (
		key: PositionHoof,
		property: keyof IDiagnosisBlock,
		value: any
	) => {
		setCheckupInfo(prevState => {
			const updatedDiagnosis = [...prevState.diagnosis]
			const index = updatedDiagnosis.findIndex(
				diagnosis => diagnosis.position === key
			)

			if (property === 'position' && value === false) {
				// Удаляем объект, если отключен чекбокс
				if (index !== -1) {
					updatedDiagnosis.splice(index, 1)
				}
			} else {
				if (index !== -1) {
					// Обновляем существующий объект
					const updatedBlock = { ...updatedDiagnosis[index] }

					if (property === 'sickness') {
						const sicknessIndex = updatedBlock.sickness.indexOf(value)
						if (sicknessIndex !== -1) {
							// Удаляем элемент из массива sickness
							updatedBlock.sickness.splice(sicknessIndex, 1)
							if (!updatedBlock.sickness_id) {
								const prevIds = updatedBlock.sickness.map(el => el.id)
								updatedBlock.sickness_id = prevIds
							}
							updatedBlock.sickness_id.splice(sicknessIndex, 1)
						} else {
							// Добавляем элемент в массив sickness
							updatedBlock.sickness.push(value)
							if (!updatedBlock.sickness_id) {
								updatedBlock.sickness_id = []
							}
							updatedBlock.sickness_id.push(value.id)
						}
					} else {
						updatedBlock[property] = value
					}

					updatedDiagnosis[index] = updatedBlock
				} else {
					// Создаем новый объект, если его не существует
					const newBlock: IDiagnosisBlock = {
						position: key,
						sickness: property === 'sickness' ? [value] : [],
						comment: property === 'comment' ? value : '',
					}
					updatedDiagnosis.push(newBlock)
				}
			}

			return {
				...prevState,
				diagnosis: updatedDiagnosis,
			}
		})
	}
	const checkSicknessInDiagnosisList = (position: PositionHoof) => {
		const findElement = diagnosisInfo.find(el => el.position === position)
		if (findElement) {
			const idsFromAvailableDiagnosisList = findElement.sickness
				.map(sickness => diagnosesList.find(el => el.id === sickness.id)?.id)
				.filter(id => id !== undefined) as number[]
			return idsFromAvailableDiagnosisList
		}
		return []
	}

	const checkCommentInDiagnosisList = (position: PositionHoof) => {
		const findElement = diagnosisInfo.find(el => el.position === position)
		if (findElement) {
			return findElement.comment
		}
		return ''
	}
	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'row'}
				width={'100%'}
				alignItems={'flex-start'}
				gap={'40px'}
			>
				<Box width={'260px'}>
					<TypographyHeaderSecondary
						sx={{ color: isError ? COLORS.error : COLORS.black }}
					>
						Лечение
					</TypographyHeaderSecondary>
				</Box>
			</Box>

			<>
				<Box
					display={'flex'}
					flexDirection={'row'}
					width={'100%'}
					alignItems={'flex-start'}
					gap={'40px'}
				>
					<Box width={'260px'}>
						<TypographySecondary14>Передние копыта</TypographySecondary14>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={isFrontLeftCheckbox}
							onClick={() =>
								changeInfo(
									PositionHoof.Front_Left,
									'position',
									!isFrontLeftCheckbox
								)
							}
						/>
						<TypographyHeader14
							sx={{
								color: isError ? COLORS.error : COLORS.black,
							}}
						>
							Левое
						</TypographyHeader14>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={isFrontRightCheckbox}
							onClick={() =>
								changeInfo(
									PositionHoof.Front_Right,
									'position',
									!isFrontRightCheckbox
								)
							}
						/>
						<TypographyHeader14
							sx={{
								color: isError ? COLORS.error : COLORS.black,
							}}
						>
							Правое
						</TypographyHeader14>
					</Box>
				</Box>

				<Box
					display={'flex'}
					flexDirection={'row'}
					width={'100%'}
					alignItems={'flex-start'}
					gap={'40px'}
				>
					<Box width={'260px'} sx={{ position: 'relative' }}>
						<HoofIcon
							onClick={() =>
								changeInfo(
									PositionHoof.Front_Right,
									'position',
									!isFrontRightCheckbox
								)
							}
							stroke={isFrontRightCheckbox ? COLORS.health : COLORS.icon}
							fill={isFrontRightCheckbox ? COLORS.health : 'transparent'}
							style={{
								position: 'absolute',
								right: ' 120px',
								top: '20px',
								cursor: 'pointer',
							}}
						/>
						<HoofIcon
							onClick={() => {
								changeInfo(
									PositionHoof.Front_Left,
									'position',
									!isFrontLeftCheckbox
								)
							}}
							stroke={isFrontLeftCheckbox ? COLORS.health : COLORS.icon}
							fill={isFrontLeftCheckbox ? COLORS.health : 'transparent'}
							style={{
								position: 'absolute',
								left: ' 30px',
								top: '50px',
								cursor: 'pointer',
							}}
						/>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<AutocompleteInput
							isError={
								disposeRecommended &&
								isFrontLeftCheckbox &&
								checkSicknessInDiagnosisList(PositionHoof.Front_Left).length ===
									0
							}
							widthForInput={'100%'}
							isDisable={!isFrontLeftCheckbox}
							onClick={id => {
								const findDiagone = diagnosesList.find(el => el.id === id)
								changeInfo(PositionHoof.Front_Left, 'sickness', findDiagone)
							}}
							selectedValues={checkSicknessInDiagnosisList(
								PositionHoof.Front_Left
							)}
							availableList={diagnosesList}
							label={'Диагноз'}
							loading={diagnosesListPending}
							limitTags={2}
						/>
						<InputWithoutRestrictions
							isError={
								disposeRecommended &&
								isFrontLeftCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Front_Left) === ''
							}
							value={checkCommentInDiagnosisList(PositionHoof.Front_Left)}
							onChange={e =>
								changeInfo(PositionHoof.Front_Left, 'comment', e.target.value)
							}
							isMultiline={true}
							label={'Комментарий'}
							width={'100%'}
							height={'120px'}
							rows={3}
							maxLength={140}
							isDisable={!isFrontLeftCheckbox}
							helperText={
								disposeRecommended &&
								isFrontLeftCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Front_Left) === '' &&
								'Обязательное поле'
							}
						/>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<AutocompleteInput
							isError={
								disposeRecommended &&
								isFrontRightCheckbox &&
								checkSicknessInDiagnosisList(PositionHoof.Front_Right)
									.length === 0
							}
							widthForInput={'100%'}
							isDisable={!isFrontRightCheckbox}
							onClick={id => {
								const findDiagone = diagnosesList.find(el => el.id === id)
								changeInfo(PositionHoof.Front_Right, 'sickness', findDiagone)
							}}
							selectedValues={checkSicknessInDiagnosisList(
								PositionHoof.Front_Right
							)}
							availableList={diagnosesList}
							label={'Диагноз'}
							loading={diagnosesListPending}
							limitTags={2}
						/>
						<InputWithoutRestrictions
							isError={
								disposeRecommended &&
								isFrontRightCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Front_Right) === ''
							}
							value={checkCommentInDiagnosisList(PositionHoof.Front_Right)}
							onChange={e =>
								changeInfo(PositionHoof.Front_Right, 'comment', e.target.value)
							}
							isMultiline={true}
							label={'Комментарий'}
							width={'100%'}
							height={'120px'}
							rows={3}
							maxLength={140}
							isDisable={!isFrontRightCheckbox}
							helperText={
								disposeRecommended &&
								isFrontRightCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Front_Right) === '' &&
								'Обязательное поле'
							}
						/>
					</Box>
				</Box>

				<Box
					display={'flex'}
					flexDirection={'row'}
					width={'100%'}
					alignItems={'flex-start'}
					gap={'40px'}
				>
					<Box width={'260px'}>
						<TypographySecondary14>Задние копыта</TypographySecondary14>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={isRearLeftCheckbox}
							onClick={() =>
								changeInfo(
									PositionHoof.Rear_Left,
									'position',
									!isRearLeftCheckbox
								)
							}
						/>
						<TypographyHeader14
							sx={{
								color: isError ? COLORS.error : COLORS.black,
							}}
						>
							Левое
						</TypographyHeader14>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={isRearRightCheckbox}
							onClick={() =>
								changeInfo(
									PositionHoof.Rear_Right,
									'position',
									!isRearRightCheckbox
								)
							}
						/>
						<TypographyHeader14
							sx={{
								color: isError ? COLORS.error : COLORS.black,
							}}
						>
							Правое
						</TypographyHeader14>
					</Box>
				</Box>

				<Box
					display={'flex'}
					flexDirection={'row'}
					width={'100%'}
					alignItems={'flex-start'}
					gap={'40px'}
				>
					<Box width={'260px'} sx={{ position: 'relative' }}>
						<HoofIcon
							onClick={() =>
								changeInfo(
									PositionHoof.Rear_Right,
									'position',
									!isRearRightCheckbox
								)
							}
							stroke={isRearRightCheckbox ? COLORS.health : COLORS.icon}
							fill={isRearRightCheckbox ? COLORS.health : 'transparent'}
							style={{
								position: 'absolute',
								right: '120px',
								top: '20px',
								cursor: 'pointer',
							}}
						/>
						<HoofIcon
							onClick={() =>
								changeInfo(
									PositionHoof.Rear_Left,
									'position',
									!isRearLeftCheckbox
								)
							}
							stroke={isRearLeftCheckbox ? COLORS.health : COLORS.icon}
							fill={isRearLeftCheckbox ? COLORS.health : 'transparent'}
							style={{
								position: 'absolute',
								left: ' 30px',
								top: '50px',
								cursor: 'pointer',
							}}
						/>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<AutocompleteInput
							isError={
								disposeRecommended &&
								isRearLeftCheckbox &&
								checkSicknessInDiagnosisList(PositionHoof.Rear_Left).length ===
									0
							}
							widthForInput={'100%'}
							isDisable={!isRearLeftCheckbox}
							onClick={id => {
								const findDiagone = diagnosesList.find(el => el.id === id)
								changeInfo(PositionHoof.Rear_Left, 'sickness', findDiagone)
							}}
							selectedValues={checkSicknessInDiagnosisList(
								PositionHoof.Rear_Left
							)}
							availableList={diagnosesList}
							label={'Диагноз'}
							loading={diagnosesListPending}
							limitTags={2}
						/>
						<InputWithoutRestrictions
							isError={
								disposeRecommended &&
								isRearLeftCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Rear_Left) === ''
							}
							value={checkCommentInDiagnosisList(PositionHoof.Rear_Left)}
							onChange={e =>
								changeInfo(PositionHoof.Rear_Left, 'comment', e.target.value)
							}
							isMultiline={true}
							label={'Комментарий'}
							width={'100%'}
							height={'120px'}
							rows={3}
							maxLength={140}
							isDisable={!isRearLeftCheckbox}
							helperText={
								disposeRecommended &&
								isRearLeftCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Rear_Left) === '' &&
								'Обязательное поле'
							}
						/>
					</Box>
					<Box
						width={'40%'}
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<AutocompleteInput
							isError={
								disposeRecommended &&
								isRearRightCheckbox &&
								checkSicknessInDiagnosisList(PositionHoof.Rear_Right).length ===
									0
							}
							widthForInput={'100%'}
							isDisable={!isRearRightCheckbox}
							onClick={id => {
								const findDiagone = diagnosesList.find(el => el.id === id)
								changeInfo(PositionHoof.Rear_Right, 'sickness', findDiagone)
							}}
							selectedValues={checkSicknessInDiagnosisList(
								PositionHoof.Rear_Right
							)}
							availableList={diagnosesList}
							label={'Диагноз'}
							loading={diagnosesListPending}
							limitTags={2}
						/>
						<InputWithoutRestrictions
							isError={
								disposeRecommended &&
								isRearRightCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Rear_Right) === ''
							}
							value={checkCommentInDiagnosisList(PositionHoof.Rear_Right)}
							onChange={e =>
								changeInfo(PositionHoof.Rear_Right, 'comment', e.target.value)
							}
							isMultiline={true}
							label={'Комментарий'}
							width={'100%'}
							height={'120px'}
							rows={3}
							maxLength={140}
							isDisable={!isRearRightCheckbox}
							helperText={
								disposeRecommended &&
								isRearRightCheckbox &&
								checkCommentInDiagnosisList(PositionHoof.Rear_Right) === '' &&
								'Обязательное поле'
							}
						/>
					</Box>
				</Box>
			</>
		</>
	)
}

export default DiagnosticBlock
