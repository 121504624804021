import { Box } from '@mui/material'
import TrimCheckupInfo from './ui/TrimCheckupInfo'

const TrimCheckupPage = () => {
	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<TrimCheckupInfo />
		</Box>
	)
}

export default TrimCheckupPage
