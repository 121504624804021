import { Box, Dialog } from '@mui/material'
import { ReactComponent as NextIcon } from 'assets/arrow_back_logo.svg'
import { ReactComponent as CloseLogo } from 'assets/cross_close_logo.svg'
import { ICameraListItem } from 'models/IFrameInfo'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
type PropsType = {
	open: boolean
	onCloseModal: () => void
	cameraList: ICameraListItem[]
}

const DigitizatedModal = (props: PropsType) => {
	const { open, onCloseModal, cameraList } = props
	const navigate = useNavigate()
	const handleNavigate = (cameraInfo: ICameraListItem) => {
		navigate(`/cattles/digitization/${cameraInfo.id}`, { state: cameraInfo })
	}
	const sortedCameraList = cameraList?.slice().sort((a, b) => {
		if (a.milking_hall < b.milking_hall) return -1
		if (a.milking_hall > b.milking_hall) return 1
		return 0
	})
	let lastMilkingHall = ''

	return (
		<Dialog
			open={open}
			onClose={onCloseModal}
			aria-labelledby='exit-warning-dialog-title'
			aria-describedby='exit-warning-dialog-description'
			PaperProps={{
				style: {
					borderRadius: '12px',
					boxShadow: 'none',
					width: '650px',
				},
			}}
		>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
				padding={'32px 24px 32px 24px'}
			>
				<TypographyHeader>Выберите место оцифровки</TypographyHeader>
				<CloseLogo
					onClick={onCloseModal}
					stroke={COLORS.secondary}
					style={{ cursor: 'pointer' }}
				/>
			</Box>
			<Box
				margin={'0px 24px 32px 0px'}
				padding={'0px 24px 14px 24px'}
				sx={{
					maxHeight: '400px',
					'&::-webkit-scrollbar': {
						width: 4,
						height: 4,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#DBDBDB',
						borderRadius: 12,
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#5222D0',
						borderRadius: 12,
					},
					overflow: 'auto',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				{sortedCameraList?.map(el => {
					const shouldRenderMilkingHall = el.milking_hall !== lastMilkingHall
					lastMilkingHall = el.milking_hall

					return (
						<React.Fragment key={el.key}>
							{shouldRenderMilkingHall && (
								<TypographySecondary14>{el.milking_hall}</TypographySecondary14>
							)}
							<CardStandart
								onClick={() => handleNavigate(el)}
								sx={{
									border: `1px solid ${COLORS.inputBorder}`,
									padding: '24px',
									cursor: 'pointer',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<TypographyPrimary16Bold>{el.title}</TypographyPrimary16Bold>
								<NextIcon style={{ transform: 'rotate(180deg)' }} />
							</CardStandart>
						</React.Fragment>
					)
				})}
			</Box>
		</Dialog>
	)
}

export default DigitizatedModal
