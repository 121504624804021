import { Box } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { COLORS } from 'styles/colors'
import SwitchiOS from 'ui/SwitchiOS'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ICheckupList, IDiagnosisBlock } from '../models'

type DisposeRecommendedBlockProps = {
	disposeRecommended: boolean
	setCheckupInfo: Dispatch<SetStateAction<ICheckupList>>
	diagnosisInfo: IDiagnosisBlock[]
}

const DisposeRecommendedBlock = (props: DisposeRecommendedBlockProps) => {
	const { disposeRecommended, setCheckupInfo, diagnosisInfo } = props

	const isEmptyDiagnoses = diagnosisInfo.length === 0
	const toggleSwitch = () => {
		setCheckupInfo(prev => {
			const currentState = prev.dispose_recommended
			return {
				...prev,
				dispose_recommended: !currentState,
			}
		})
	}
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			width={'100%'}
			alignItems={'flex-start'}
			gap={'40px'}
		>
			<Box width={'240px'}>
				<TypographyHeaderSecondary
					sx={{ color: disposeRecommended ? COLORS.error : COLORS.black }}
				>
					Рекомендовать
					<br />
					выбытие
				</TypographyHeaderSecondary>
			</Box>
			<Box>
				<SwitchiOS
					checked={disposeRecommended}
					background={COLORS.error}
					onClick={toggleSwitch}
				/>
				{isEmptyDiagnoses && disposeRecommended && (
					<TypographySecondary sx={{ color: COLORS.error, marginTop: '16px' }}>
						Выберите больные копыта и укажите диагноз
					</TypographySecondary>
				)}
			</Box>
		</Box>
	)
}

export default DisposeRecommendedBlock
