import { Box, Divider } from '@mui/material'
import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import { DateRestriction } from 'models/components'
import { ITrimsBatching } from 'models/ICattlesData'
import { useEffect } from 'react'
import { fetchExaminersList } from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import GroupDate from 'ui/datePicker/GroupDate'
import Preloader from 'ui/Preloader'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type TrimProps = {
	trims: ITrimsBatching[]
	trimLoader: boolean
	onChangeDatePicker: (date, index) => void
	changePersonForTrimById: (id, index) => void
	deleteTrim: (ids, index) => void
}

const EditTrimsBlock = (props: TrimProps) => {
	const {
		trims,
		trimLoader,
		onChangeDatePicker,
		changePersonForTrimById,
		deleteTrim,
	} = props
	const dispatch = useAppDispatch()
	const { examinersList, examinersListPending } = useAppSelector(
		state => state.cattlesSlice
	)

	useEffect(() => {
		examinersList.length === 0 && dispatch(fetchExaminersList())
	}, [])

	return (
		<Box sx={{ marginBottom: '24px' }}>
			{trimLoader || examinersListPending ? (
				<Preloader />
			) : (
				<>
					{trims.map((el, index) => (
						<div key={index}>
							<Divider sx={{ margin: '24px 0px' }} />
							{/* <Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								mb={'24px'}
								gap={'8px'}
							>
								<TypographySecondary14>{`Обрезка ${
									index + 1
								}`}</TypographySecondary14>
								<CloseIcon
									onClick={() => deleteTrim(el.trim_ids, index)}
									style={{
										cursor: 'pointer',
										stroke: COLORS.secondary,
									}}
									width={'16px'}
									height={'16px'}
								/>
							</Box> */}
							<TypographySecondary14 mb={'24px'}>
								{'Дата следующей обрезки и исполнитель'}
							</TypographySecondary14>
							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'start'}
								gap={'32px'}
							>
								<GroupDate
									value={
										el.target_date ? new Date(el.target_date) : el.target_date
									}
									onChange={newDate => onChangeDatePicker(newDate, index)}
									restrictionType={DateRestriction.FUTURE}
								/>
								<AutocompleteInput
									key={`TrimAutocomplete${index}`}
									widthForInput={'430px'}
									onClick={id => {
										changePersonForTrimById(id, index)
									}}
									isCheckbox
									availableList={examinersList}
									loading={examinersListPending}
									selectedValues={[el.responsible.id]}
									label={'Исполнитель'}
									limitTags={1}
								/>
							</Box>
						</div>
					))}
				</>
			)}
		</Box>
	)
}

export default EditTrimsBlock
