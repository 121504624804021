import { COLORS } from 'styles/colors'

type PropsType = {
	bgColor?: string
	okIconColor?: string
	width?: string
	height?: string
}
const OkIcon = (props: PropsType) => {
	const {
		bgColor = COLORS.success,
		okIconColor = 'white',
		width = '34px',
		height = '34px',
	} = props

	return (
		<svg width={width} height={height} viewBox='0 0 16 16' fill='none'>
			<circle cx='8' cy='8' r='8' fill={bgColor} />
			<path
				d='M4.5 7.5L7.5 11.75L11.5 4.5'
				stroke={okIconColor}
				strokeWidth='1.33333'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default OkIcon
