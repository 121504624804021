import axAPI from '../http'

export default class ConstantsApiService {
	static getConstants = async () => {
		return axAPI({
			method: 'GET',
			url: '/constants',
		})
	}
}
