import Box from '@mui/material/Box/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { ReactComponent as PlusLogo } from 'assets/plan_edit_plus_logo.svg'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PlanApiService from 'services/planApiService'
import LargeCardButton from 'ui/buttons/LargeCardButton'
import { useAppDispatch } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'
import { COLORS } from '../../styles/colors'
import { ButtonCancel } from '../../ui/buttons/ButtonCancel'
import { ButtonUI } from '../../ui/buttons/ButtonUI'
import EditPlanPageCleaning from './EditPlanPageCleaning'
import EditPlanPageDistribution from './EditPlanPageDistribution'
import EditPlanPageNudge from './EditPlanPageNudge'
import NotifBox from './NotifBox'
interface IFeedPlanType {
	active: boolean
	deviation: number
	distributed_food_offset: number
	id: number
	time: string
	type: string
	weight: number
	feed_fact: IFeedFact[]
}
interface IFeedFact {
	date: string
	food_weight: number
	plan_time: string
	plan_weight: number
	plan_weight_percent: number
	group: IFeedFactGroup
}
interface IFeedFactGroup {
	cattles_qty: number
	id: number
	title: string
	barn: IFeedFactGroupBarn
	ration: IFeedFactGroupRation
}

interface IFeedFactGroupBarn {
	company: number
	id: number
	title: string
}
interface IFeedFactGroupRation {
	title: string
	id: number
	components: IFeedFactGroupRationComponents[]
}
interface IFeedFactGroupRationComponents {
	item: string
	coef: number
	itemKey: string
}
interface IDeviation {
	deviationDistribution: number
	deviationCleaning: number
	deviationNudge: number
}
const EditPlanPage = props => {
	const [feedPlanDistribution, setFeedPlanDistribution] =
		useState<IFeedPlanType[]>()
	const [feedPlanCleaning, setFeedPlanCleaning] = useState<IFeedPlanType[]>()
	const [feedPlanNudge, setFeedPlanNudge] = useState<IFeedPlanType[]>()
	const [adapterFeedPlan, setAdapterFeedplan] = useState<any[]>([])
	const [adapterNudgePlan, setAdapterNudgePlan] = useState<any[]>([])

	const [finallArrayForPost, setFinallArrayForPost] = useState<any[]>([])
	const [deviationArray, setDeviationArray] = useState<IDeviation>({
		deviationDistribution: 0,
		deviationCleaning: 0,
		deviationNudge: 0,
	})
	const isFullCleaningPlan = feedPlanCleaning?.length > 0
	const isFullNudgePlan = feedPlanNudge?.length > 0

	const [showCleaningPlan, setShowCleaningPlan] = useState<boolean | null>(null)
	const [showNudgePlan, setShowNudgePlan] = useState<boolean | null>(
		isFullNudgePlan
	)

	useEffect(() => {
		setShowCleaningPlan(isFullCleaningPlan)
	}, [isFullCleaningPlan])
	useEffect(() => {
		setShowNudgePlan(isFullNudgePlan)
	}, [isFullNudgePlan])

	const today = new Date()
	const tomorrow = new Date(today)
	tomorrow.setDate(tomorrow.getDate() + 1)

	useEffect(() => {
		PlanApiService.getFeedplans(tomorrow.toISOString().slice(0, 10)).then(
			response => {
				const distribution = response.data.filter(
					el => el.type === 'distribution'
				)
				const cleaning = response.data.filter(el => el.type === 'cleaning')
				const nudge = response.data.filter(el => el.type === 'nudge')
				setFeedPlanDistribution(distribution)
				setFeedPlanCleaning(cleaning)
				setFeedPlanNudge(nudge)
				setDeviationArray(prevData => ({
					...prevData,
					deviationDistribution:
						distribution?.length > 0 ? distribution[0].deviation : 0,
					deviationCleaning: cleaning?.length > 0 ? cleaning[0].deviation : 0,
					deviationNudge: nudge?.length > 0 ? nudge[0].deviation : 0,
				}))
			}
		)
	}, [])

	const isErrorState = adapterNudgePlan?.some(el => el.errorValuesCell === true)
	useEffect(() => {
		if (isErrorState === false && adapterNudgePlan?.length > 0) {
			const newArray = []
			for (let i = 0; i < adapterNudgePlan?.length; i++) {
				for (let j = 0; j < adapterNudgePlan[i].time?.length; j++) {
					if (
						adapterNudgePlan[i].time[j] !== '' &&
						adapterNudgePlan[i].weightPercent[j] !== ''
					) {
						const newObj = {
							type: 'distribution',
							deviation: deviationArray.deviationDistribution,
							group_id: adapterNudgePlan[i].group_id,
							time: adapterNudgePlan[i].time[j],
							weight_percent: adapterNudgePlan[i].weightPercent[j],
							correction_percent: +adapterNudgePlan[i].correctionPercent,
						}
						newArray.push(newObj)
					}
				}
			}

			for (let i = 0; i < adapterNudgePlan?.length; i++) {
				for (let j = 0; j < adapterNudgePlan[i].timeCleaning?.length; j++) {
					if (adapterNudgePlan[i].timeCleaning[j] !== '') {
						const newObj = {
							type: 'cleaning',
							deviation: deviationArray.deviationCleaning,
							group_id: adapterNudgePlan[i].group_id,
							time: adapterNudgePlan[i].timeCleaning[j],
						}
						newArray.push(newObj)
					}
				}
			}

			for (let i = 0; i < adapterNudgePlan?.length; i++) {
				for (let j = 0; j < adapterNudgePlan[i].timeNudge?.length; j++) {
					if (
						adapterNudgePlan[i].deviationTimeNudge === undefined &&
						adapterNudgePlan[i].timeNudge[j] !== ''
					) {
						const newObj = {
							type: 'nudge',
							deviation: deviationArray.deviationNudge,
							group_id: adapterNudgePlan[i].group_id,
							time: adapterNudgePlan[i].timeNudge[j],
						}
						newArray.push(newObj)
					}
				}
			}
			setFinallArrayForPost(newArray)
		}
	}, [adapterNudgePlan, isErrorState, deviationArray])

	const navigate = useNavigate()
	const urlParams = useParams()
	const cancelButton = () => {
		navigate(`/feed/plan/`)
	}
	const dispatch = useAppDispatch()
	const saveChangeAtServer = () => {
		PlanApiService.postChangedPlans(finallArrayForPost)
			.then(response => {
				navigate(
					props.isFirstTry ? `/fill/${urlParams.id}/done` : '/feed/plan',
					{ state: { showNotification: true } }
				)
				!props.isFirstTry &&
					dispatch(
						notifyUser({
							title: 'План кормления',
							description: 'Изменения успешно сохранены',
							isError: false,
						})
					)
			})
			.catch(error => {
				dispatch(
					notifyUser({
						title: 'План кормления',
						description: 'Ошибка при сохранении плана кормления',
						isError: true,
					})
				)
			})
	}
	if (!feedPlanDistribution) return <></>
	const removePlan = (plan: string) => {
		setAdapterFeedplan(prevState => {
			const newState = prevState.map(item => {
				if (item[plan]) {
					return {
						...item,
						[plan]: [''],
					}
				}
				return item
			})
			return newState
		})
		setAdapterNudgePlan(prevState => {
			const newState = prevState.map(item => {
				if (item[plan]) {
					return {
						...item,
						[plan]: [''],
					}
				}
				return item
			})
			return newState
		})
		plan === 'timeCleaning' && setShowCleaningPlan(false)
		plan === 'timeNudge' && setShowNudgePlan(false)
	}
	return (
		<>
			{!feedPlanDistribution ||
			!feedPlanNudge ||
			!feedPlanCleaning ||
			!adapterFeedPlan ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
						paddingLeft: props.isFirstTry ? '0px' : '20px',
						margin: '0px',
						width: '81vw',
					}}
				>
					{!props.isFirstTry && <NotifBox />}
					<EditPlanPageDistribution
						isFirstTry={props.isFirstTry}
						setDeviationArray={setDeviationArray}
						setFeedPlanDistribution={setFeedPlanDistribution}
						feedPlanDistribution={feedPlanDistribution}
						setAdapterFeedplan={setAdapterFeedplan}
						setAdapterNudgePlan={setAdapterNudgePlan}
					/>
					{showCleaningPlan && (
						<EditPlanPageCleaning
							removePlan={removePlan}
							setDeviationArray={setDeviationArray}
							setFeedPlanCleaning={setFeedPlanCleaning}
							feedPlanCleaning={feedPlanCleaning}
							adapterFeedPlan={adapterFeedPlan}
							adapterNudgePlan={adapterNudgePlan}
							setAdapterNudgePlan={setAdapterNudgePlan}
						/>
					)}
					{showNudgePlan && (
						<EditPlanPageNudge
							removePlan={removePlan}
							setDeviationArray={setDeviationArray}
							feedPlanNudge={feedPlanNudge}
							adapterNudgePlan={adapterNudgePlan}
							setAdapterNudgePlan={setAdapterNudgePlan}
							adapterFeedPlan={adapterFeedPlan}
							setFeedPlanNudge={setFeedPlanNudge}
						/>
					)}
					{!showCleaningPlan && (
						<LargeCardButton
							title={'Добавить уборку корма'}
							onClick={() => setShowCleaningPlan(true)}
							endAdornment={<PlusLogo stroke={COLORS.secondary} />}
						/>
					)}
					{!showNudgePlan && (
						<LargeCardButton
							title={'Добавить подталкивание корма'}
							onClick={() => setShowNudgePlan(true)}
							endAdornment={<PlusLogo stroke={COLORS.secondary} />}
						/>
					)}
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'end',
							marginTop: '-24px',
						}}
					>
						{!props.isFirstTry && (
							<ButtonCancel onClick={cancelButton}>Отменить</ButtonCancel>
						)}
						<ButtonUI
							onClick={saveChangeAtServer}
							disabled={isErrorState ? true : false}
							sx={{ height: '56px' }}
						>
							{props.isFirstTry
								? 'Сохранить и завершить заполнение'
								: 'Сохранить'}
						</ButtonUI>
					</Box>
				</Box>
			)}
		</>
	)
}

export default EditPlanPage
