import { Box, Collapse, Divider } from '@mui/material'
import { ReactComponent as ArrowOpen } from 'assets/arrow_close_logo.svg'
import { ReactComponent as CheckupIcon } from 'assets/checkup-icon.svg'
import OkIcon from 'assets/okVerifyIcon'
import CheckupCowModal from 'components/cattles/lamenessPage/shared/checkupCowModal/CheckupCowModal'
import { getTranslationStatus } from 'components/cattles/lamenessPage/shared/processes'
import InputWithoutRestrictions from 'components/shared/textFields/InputWithoutRestrictions'
import { DateRestriction } from 'models/components'
import { ICowsList, ITrims } from 'models/ICattlesData'
import { IConstants } from 'models/IConstants'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { COLORS } from 'styles/colors'
import CustomButton from 'ui/buttons/CustomButton'
import { CardStandart } from 'ui/CardStandart'
import GroupDate from 'ui/datePicker/GroupDate'
import SwitchiOS from 'ui/SwitchiOS'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type TrimCardProps = {
	cowInfo: ICowsList
	constants: IConstants
	treatmentInfo: ITrims
	userId: number
	setCompleteCount: Dispatch<SetStateAction<any>>
}

const CheckupTrimCard = (props: TrimCardProps) => {
	const { cowInfo, constants, treatmentInfo, userId, setCompleteCount } = props

	const initCheckup = {
		isCheckup: false,
		comment: '',
		treatment: treatmentInfo,
		disposeRecommended: undefined,
		milkRejected: false,
		isChangedTreatment: false,
		nextTrimDate: moment()
			.add(constants[constants?.next_trim_field], 'days')
			.format('YYYY-MM-DD'),
		doneDateTime: undefined,
	}
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [openCard, setOpenCard] = useState<boolean>(false)
	const [isTrimComplete, setIsTrimComplete] = useState<boolean>(false)
	const [checkupInfo, setCheckupInfo] = useState(initCheckup)

	const toggleSwitch = () => {
		setCheckupInfo(prev => {
			return { ...prev, isCheckup: !prev.isCheckup }
		})
	}
	const dispatch = useAppDispatch()
	const closetExamination = checkupInfo?.treatment?.closest_examination

	const isMilkRejected =
		(closetExamination?.milk_reject_end &&
			closetExamination?.milk_reject_start) ||
		checkupInfo.milkRejected

	const isDisposeRecommended =
		closetExamination?.dispose_recommended || checkupInfo.disposeRecommended

	const isDisableCheckupBtn =
		!checkupInfo.isCheckup || checkupInfo.isChangedTreatment

	useEffect(() => {
		if (checkupInfo.isChangedTreatment) {
			setCompleteCount(prev => {
				return {
					...prev,
					treatmentQty: prev.treatmentQty + 1,
				}
			})
		}
	}, [checkupInfo.isChangedTreatment])
	const handleChangeComment = value => {
		setCheckupInfo(prev => {
			return {
				...prev,
				comment: value,
			}
		})
	}
	const onChangeDatePicker = (date: Date | null) => {
		setCheckupInfo(prev => {
			return {
				...prev,
				nextTrimDate: moment(date).format('YYYY-MM-DD'),
			}
		})
	}
	const submitForm = () => {
		setIsTrimComplete(true)
		CattlesApiService.patchTrimByTrimId(
			treatmentInfo.id,
			moment(checkupInfo.nextTrimDate).valueOf(),
			userId,
			checkupInfo.comment,
			moment().valueOf()
		)
			.then(() => {
				setOpenCard(false)
				setCompleteCount(prev => {
					return {
						...prev,
						headQty: prev.headQty + 1,
					}
				})
				dispatch(
					notifyUser({
						isError: false,
						title: 'Данные успешно сохранены',
						description: 'Изменения применены.',
					})
				)
			})
			.catch(error => {
				dispatch(
					notifyUser({
						isError: true,
						title: 'Произошла ошибка',
						description: error.message,
					})
				)
			})
	}

	return (
		<CardStandart
			sx={{
				padding: '24px',
			}}
		>
			<Box
				onClick={() => setOpenCard(prev => !prev)}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					cursor: 'pointer',
				}}
			>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'8px'}
				>
					<TypographyHeader>{cowInfo.tag_id}</TypographyHeader>
					<TypographySecondary>{cowInfo.group.title}</TypographySecondary>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'8px'}
				>
					{checkupInfo.isCheckup && isTrimComplete ? (
						<OkIcon bgColor={COLORS.health} width={'24px'} height={'24px'} />
					) : (
						<TypographySecondary14>Заполнить данные</TypographySecondary14>
					)}
					<ArrowOpen
						stroke={COLORS.icon}
						style={{
							transform: openCard ? 'rotate(180deg)' : 'rotate(0deg)',
							transition: 'all ease-in .1s',
						}}
					/>
				</Box>
			</Box>
			<Collapse
				in={openCard}
				unmountOnExit
				timeout='auto'
				sx={{ marginTop: '32px' }}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={'24px'}
					width={'100%'}
				>
					<Box
						display={'flex'}
						flexDirection={'row'}
						width={'100%'}
						gap={'60px'}
					>
						<Box width={'50%'}>
							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								gap={'8px'}
								mb={'16px'}
							>
								<SwitchiOS
									checked={checkupInfo.isCheckup}
									background={COLORS.health}
									onClick={toggleSwitch}
								/>
								<TypographyPrimary>Обрезка</TypographyPrimary>
							</Box>
							<InputWithoutRestrictions
								value={checkupInfo.comment}
								onChange={e => handleChangeComment(e.target.value)}
								isMultiline
								label={'Комментарий'}
								width={'100%'}
								height={'100px'}
								rows={3}
								maxLength={140}
								isDisable={!checkupInfo.isCheckup}
							/>
						</Box>
						<Box width={'50%'}>
							<TypographyPrimary sx={{ marginBottom: '26px' }}>
								Дата следующей обрезки
							</TypographyPrimary>
							<GroupDate
								value={new Date(checkupInfo.nextTrimDate)}
								onChange={newDate => onChangeDatePicker(newDate)}
								restrictionType={DateRestriction.FUTURE}
							/>
						</Box>
					</Box>
					<Divider />
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						gap={'8px'}
					>
						<TypographyPrimary>Лечение</TypographyPrimary>
						{isMilkRejected && (
							<Box
								sx={{
									padding: '4px 8px',
									borderRadius: '12px',
									backgroundColor: COLORS.error30,
								}}
							>
								<TypographySecondary sx={{ color: COLORS.error }}>
									Браковка
								</TypographySecondary>
							</Box>
						)}
						{isDisposeRecommended && (
							<Box
								sx={{
									padding: '4px 8px',
									borderRadius: '12px',
									backgroundColor: COLORS.error30,
								}}
							>
								<TypographySecondary sx={{ color: COLORS.error }}>
									Рекомендована на выбытие
								</TypographySecondary>
							</Box>
						)}
					</Box>
					<Box display={'flex'} flexDirection={'column'} gap={'8px'}>
						{closetExamination?.diagnosis.map(diagnose => (
							<Box
								key={diagnose.position}
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
							>
								<TypographySecondary14>
									{getTranslationStatus(diagnose.position)}:
								</TypographySecondary14>
								<TypographyPrimary>
									{diagnose.sickness.map(el => el.title).join(', ')}
								</TypographyPrimary>
							</Box>
						))}
					</Box>
					<CustomButton
						titleColor={COLORS.icon}
						borderColor={COLORS.icon}
						bgActiveColor={COLORS.icon}
						title={'Провести лечебный осмотр'}
						onClick={() => setOpenModal(true)}
						iconElement={
							<CheckupIcon
								stroke={isDisableCheckupBtn ? COLORS.inputBorder : COLORS.icon}
							/>
						}
						disable={isDisableCheckupBtn}
					/>
				</Box>
				<Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
					<CustomButton
						title={'Готово'}
						onClick={submitForm}
						disable={!checkupInfo.isCheckup}
					/>
				</Box>
			</Collapse>
			{openModal && (
				<CheckupCowModal
					openModal={openModal}
					cowInfo={cowInfo}
					onClose={() => setOpenModal(false)}
					trimData={{
						isTrim: checkupInfo.isCheckup,
						comment: checkupInfo.comment,
						target_date: moment(checkupInfo.nextTrimDate).valueOf(),
					}}
					treatmentInfo={checkupInfo.treatment}
					callbackFn={setCheckupInfo}
				/>
			)}
		</CardStandart>
	)
}

export default CheckupTrimCard
