import { Box, Divider } from '@mui/material'
import { ReactComponent as HoofLogo } from 'assets/hoof-icon.svg'
import { ReactComponent as EditLogo } from 'assets/ration_edit_logo.svg'
import FilterContent from 'components/cattles/lamenessPage/shared/filterElements/FilterContent'
import TabsFilter from 'components/shared/breadCrumbs/TabsFilter'
import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import useLamenessFilter from 'hook/useLamenessFilter'
import useCowsNextPage from 'hook/useNextPage'
import { ICowsList } from 'models/ICattlesData'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import CustomButton from 'ui/buttons/CustomButton'
import { CardStandart } from 'ui/CardStandart'
import CustomPagination from 'ui/CustomPagination'
import RangeDate from 'ui/datePicker/RangeDate'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { isTablet } from 'variables'
import { TrimStatus } from '../models'
import { titleForTrimFilter } from '../shared'
import TrimTable from '../shared/TrimTable'

const TrimList = () => {
	const navigate = useNavigate()
	const [dateRange, setDateRange] = useState({
		startDate: undefined,
		endDate: undefined,
	})
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
	const [selectedCows, setSelectedCows] = useState<ICowsList[]>([])
	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
		setActiveFilters(prev => {
			return {
				...prev,
				dateRange: { startDate: value[0], endDate: value[1] },
			}
		})
	}
	const isDisableCowEditButton = selectedCows.length === 0

	const toggleCheckbox = tagId => {
		if (selectedCheckboxes.includes(tagId)) {
			const newCheckboxesState = selectedCheckboxes.filter(el => el !== tagId)
			const newSelectedCowsState = selectedCows.filter(
				el => el.tag_id !== tagId
			)
			setSelectedCheckboxes(newCheckboxesState)
			setSelectedCows(newSelectedCowsState)
		} else {
			const findCow = cowsList.find(el => el.tag_id === tagId)
			setSelectedCows(prev => [...prev, findCow])
			setSelectedCheckboxes(prevState => [...prevState, tagId])
		}
	}

	const toggleAllCheckboxes = () => {
		const allIds = cowsList?.reduce((acc, el) => {
			if (el.tag_id) acc.push(el.tag_id)
			return acc
		}, [])
		const allCows = cowsList?.reduce((acc, el) => {
			if (el.tag_id) acc.push(el)
			return acc
		}, [])
		if (selectedCheckboxes.length === allIds.length) {
			setSelectedCheckboxes([])
			setSelectedCows([])
		} else {
			setSelectedCheckboxes(allIds)
			setSelectedCows(allCows)
		}
	}
	const [page, setPage] = useState<number>(1)
	const initialPageSize = 36
	const {
		activeFilters,
		openFilter,
		toggleFilter,
		setActiveFilters,
		setOpenFilter,
		handleDayChange,
		returnInitialProps,
		turnOnFilters,
		isFetchComplete,
		isEqualFilter,
		isEqualLamenessFilter,
		isEqualGroupsFilter,
		isEqualTrimFilter,
		isEqualRespPersonsFilter,
	} = useLamenessFilter()

	const propForTable = {
		params: activeFilters,
		isFetchComplete,
		page,
		setPage,
		initialPageSize,
		isFetchCowsListEnabled: !openFilter,
		initialTabs: initialTabs,
	}

	const navigateOnEditTrimPage = () => {
		!isDisableCowEditButton &&
			navigate('/cattles/trim/edit', { state: selectedCows })
	}
	const navigateOnCheckupTrimPage = () => {
		!isDisableCowEditButton &&
			navigate('/cattles/trim/check-up', { state: selectedCows })
	}

	const {
		cowsList,
		totalCountPages,
		fetchingCowsList,
		availableTabs,
		availableTags,
	} = useCowsNextPage(propForTable)
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
			<RangeDate
				onChangeUpdateDate={onChangeCallback}
				startDate={dateRange.startDate}
				endDate={dateRange.endDate}
				todayFilter={true}
				weekFilter={true}
				monthFilter={true}
				isNeedFutureDates
				disabledDates={null}
			/>
			<CardStandart sx={{ padding: '24px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<TypographyHeader>Список голов</TypographyHeader>
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						gap={'24px'}
					>
						<AutocompleteInput
							label={'Поиск по номеру бирки'}
							widthForInput={'400px'}
							isCheckbox
							availableList={availableTags}
							onClick={id => {
								setActiveFilters(prev => {
									const tags = prev.tags
									const findTag = availableTags.find(el => el.id === id)
									const isPresent = tags.includes(findTag.id)
									const updateTags = isPresent
										? tags.filter(item => item !== findTag.id)
										: [...tags, findTag.id]

									return {
										...prev,
										tags: updateTags,
									}
								})
							}}
							selectedValues={activeFilters?.tags}
						/>
						<CustomButton
							title={isTablet ? null : 'Провести обрезку'}
							onClick={navigateOnCheckupTrimPage}
							iconElement={
								<HoofLogo
									stroke={
										isDisableCowEditButton ? COLORS.inputBorder : COLORS.main
									}
								/>
							}
							disable={isDisableCowEditButton}
						/>
						<CustomButton
							title={isTablet ? null : 'Редактировать'}
							onClick={navigateOnEditTrimPage}
							iconElement={
								<EditLogo
									fill={
										isDisableCowEditButton ? COLORS.inputBorder : COLORS.main
									}
								/>
							}
							disable={isDisableCowEditButton}
						/>
					</Box>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'24px'}
				>
					<TabsFilter
						availableTabs={availableTabs}
						onClick={key => {
							returnInitialProps('all')
							if (key === TrimStatus.Missed) {
								setActiveFilters(prev => {
									return {
										...prev,
										isLateTrims: true,
									}
								})
							}
						}}
					/>
					<FilterContent
						activeFilters={activeFilters}
						openFilter={openFilter}
						toggleFilter={toggleFilter}
						setActiveFilters={setActiveFilters}
						setOpenFilter={setOpenFilter}
						handleDayChange={handleDayChange}
						returnInitialProps={returnInitialProps}
						turnOnFilters={turnOnFilters}
						titleForFilter={titleForTrimFilter}
						isEqualFilter={isEqualFilter}
						isEqualLamenessFilter={isEqualLamenessFilter}
						isEqualTrimFilter={isEqualTrimFilter}
						isEqualGroupsFilter={isEqualGroupsFilter}
						isEqualRespPersonsFilter={isEqualRespPersonsFilter}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					<TrimTable
						selectedCheckboxes={selectedCheckboxes}
						cattlesInfo={cowsList}
						fetchingCowsList={fetchingCowsList}
						toggleCheckbox={toggleCheckbox}
						toggleAllCheckboxes={toggleAllCheckboxes}
					/>
					<Divider />
					<CustomPagination
						onChangeFromServer={setPage}
						count={initialPageSize}
						totalCount={totalCountPages}
					/>
				</Box>
			</CardStandart>
		</Box>
	)
}

export default TrimList

const initialTabs = [
	{
		key: 'all',
		title: 'Все',
		description: null,
	},
	{
		key: TrimStatus.Missed,
		title: 'Пропущено',
		description: null,
	},
]
