import { Box } from '@mui/material'
import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import { useCallbackPrompt } from 'hook/useCallbackPrompt'
import { ICowsList, ITrims } from 'models/ICattlesData'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CattlesApiService from 'services/cattlesApiServices'
import { getConstantsList } from 'store/constantsSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { fetchUserData } from 'store/userSlice'
import { CardStandart } from 'ui/CardStandart'
import ExitWarningModal from 'ui/modal/ExitWarningModal'
import Preloader from 'ui/Preloader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { TypographySecondaryBold14 } from 'ui/typography/TypographySecondaryBold14'
import CheckupTrimCard from '../shared/CheckupTrimCard'

const TrimCheckupInfo = () => {
	const location = useLocation()
	const [cowsInfo, setCowsInfo] = useState<ICowsList[]>(
		(location.state as ICowsList[]) || []
	)
	const [treatmentInfo, setTreatmentInfo] = useState<ITrims[]>()
	const [fetchTreatment, setFetchTreatment] = useState<boolean>(false)
	const [completeCount, setCompleteCount] = useState({
		headQty: 0,
		treatmentQty: 0,
	})

	const isOpenModal = completeCount.headQty !== cowsInfo.length
	const { showPrompt, setShowPrompt, confirmNavigation, cancelNavigation } =
		useCallbackPrompt(isOpenModal)
	const [filteredIds, setFilteredIds] = useState<number[]>([])
	const dispatch = useAppDispatch()
	const { constants, isLoadingConstants } = useAppSelector(
		state => state.constantsSlice
	)
	const { userData, userDataPending } = useAppSelector(
		state => state.userReducer
	)
	const tagsWithTitleAndIndex = cowsInfo
		.filter(el => el !== null)
		.map(el => ({
			title: el.tag_id.toString(),
			id: el.id,
		}))

	useEffect(() => {
		!constants && dispatch(getConstantsList())
		!userData && dispatch(fetchUserData())
		if (cowsInfo.length > 0) {
			setFetchTreatment(true)
			const cowIds = cowsInfo.map(el => el.id)
			CattlesApiService.getTodayTrims(cowIds)
				.then(res => {
					setTreatmentInfo(res)
					setCowsInfo(prev => {
						const trimmedCowIds = new Set(res.map(exam => exam.cow.id))
						console.log(res)
						return prev.filter(el => trimmedCowIds.has(el.id))
					})
				})
				.catch(err => console.error(err.message))
				.finally(() => setFetchTreatment(false))
		}
	}, [])

	return (
		<>
			<TypographyPrimary>
				Отметьте номера бирок, которым проведена обрезка копыт. <br />В случае
				обнаружения болезни - проведите дополнительный лечебный осмотр.
			</TypographyPrimary>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<AutocompleteInput
					key={'TrimCheck-upAutocomplete'}
					widthForInput={'430px'}
					onClick={id => {
						setFilteredIds(prev => {
							const findTag = tagsWithTitleAndIndex.find(el => el.id === id)
							const isPresent = prev.includes(findTag.id)
							const updateTags = isPresent
								? prev.filter(item => item !== findTag.id)
								: [...prev, findTag.id]

							return updateTags
						})
					}}
					availableList={tagsWithTitleAndIndex}
					selectedValues={filteredIds}
					label={'Поиск по номеру бирки'}
					limitTags={3}
					bgInputColor={'white'}
				/>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'16px'}
					mb={'24px'}
				>
					<CardStandart
						sx={{
							padding: '16px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<TypographySecondary14>
							Обработано голов:{' '}
							<span style={{ fontWeight: 600, color: 'black' }}>
								{completeCount.headQty}
							</span>{' '}
							из{' '}
							<span style={{ fontWeight: 600, color: 'black' }}>
								{cowsInfo.length}
							</span>
						</TypographySecondary14>
					</CardStandart>
					<CardStandart
						sx={{
							padding: '16px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<TypographySecondary14>
							Лечебный осмотр:{' '}
							<span style={{ fontWeight: 600, color: 'black' }}>
								{completeCount.treatmentQty}
							</span>{' '}
						</TypographySecondary14>
					</CardStandart>
				</Box>
			</Box>
			<>
				{isLoadingConstants || fetchTreatment || userDataPending ? (
					<Preloader />
				) : cowsInfo.length === 0 ? (
					<TypographySecondaryBold14 sx={{ textAlign: 'center' }}>
						По выбранным коровам на сегодня обрезок не запланировано
					</TypographySecondaryBold14>
				) : (
					<>
						{(filteredIds.length === 0
							? cowsInfo
							: cowsInfo.filter(el => filteredIds.includes(el.id))
						).map(
							el =>
								constants && (
									<CheckupTrimCard
										key={el.id}
										cowInfo={el}
										treatmentInfo={treatmentInfo?.find(
											treatment => treatment.cow.id === el.id
										)}
										constants={constants}
										userId={userData.id}
										setCompleteCount={setCompleteCount}
									/>
								)
						)}
					</>
				)}
			</>

			<ExitWarningModal
				open={showPrompt}
				onClose={cancelNavigation}
				onSave={() => setShowPrompt(false)}
				onDismiss={confirmNavigation}
				title={'Выйти без сохранения'}
				description={'Внесенные изменения будут утеряны.'}
				onSaveText={'Вернуться к редактированию'}
				onDismissText={'Выйти без сохранения'}
			/>
		</>
	)
}

export default TrimCheckupInfo
