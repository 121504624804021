import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { COLORS } from 'styles/colors'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'

type ButtonProps = {
	onClick: () => void
	title: string
	endAdornment?: ReactNode
	startAdornment?: ReactNode
	backgroundColor?: string
}

const LargeCardButton = (props: ButtonProps) => {
	const {
		onClick,
		title,
		endAdornment,
		startAdornment,
		backgroundColor = '#fff',
	} = props
	return (
		<Box
			component={'button'}
			display='flex'
			flexDirection='row'
			justifyContent='space-between'
			alignItems={'center'}
			onClick={onClick}
			sx={{
				p: '0px 44px',
				width: '100%',
				boxShadow: 'none',
				borderRadius: '8px',
				backgroundColor: backgroundColor,
				cursor: 'pointer',
				border: `1px solid ${COLORS.icon}`,
				height: '48px',
				'&:active': {
					opacity: 0.7,
				},
				'&:touch': {
					opacity: 0.7,
				},
			}}
		>
			{startAdornment ? startAdornment : <span></span>}
			<TypographyHeaderSecondary sx={{ color: COLORS.icon, fontWeight: 600 }}>
				{title}
			</TypographyHeaderSecondary>
			{endAdornment ? endAdornment : <span></span>}
		</Box>
	)
}

export default LargeCardButton
