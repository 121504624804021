import {
	Box,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableRow,
} from '@mui/material'
import { ICowsList, ITrimsBatching } from 'models/ICattlesData'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CattlesApiService from 'services/cattlesApiServices'
import { CardStandart } from 'ui/CardStandart'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import EditTrimsBlock from '../shared/EditTrimsBlock'
import HeaderCowQty from '../shared/HeaderCowQty'
import TableBodyEditTrim from '../shared/TableBodyEditTrim'
import TableHeadEditTrim from '../shared/TableHeadEditTrim'

import { useCallbackPrompt } from 'hook/useCallbackPrompt'
import useIsEqual from 'hook/useIsEqual'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { ButtonCancel } from 'ui/buttons/ButtonCancel'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import ExitWarningModal from 'ui/modal/ExitWarningModal'

const TrimInfoList = () => {
	const dispatch = useAppDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const [cowsInfo, setCowsInfo] = useState<ICowsList[]>(
		(location.state as ICowsList[]) || []
	)
	const [trims, setTrims] = useState<ITrimsBatching[]>([])
	const [initialTrims, setInitialTrims] = useState<ITrimsBatching[]>([])
	const [trimLoader, setTrimLoader] = useState<boolean>(true)
	const [deleteTrimIds, setDeleteTrimIds] = useState([])
	const [onClickBtn, setOnClickBtn] = useState<boolean>(false)
	const isEqualTrimStates = useIsEqual(trims, initialTrims)
	const isOpenModal = !isEqualTrimStates && !onClickBtn
	const { showPrompt, setShowPrompt, confirmNavigation, cancelNavigation } =
		useCallbackPrompt(isOpenModal)
	const cowQty = cowsInfo.length

	useEffect(() => {
		setTrimLoader(true)
		const cow_ids = []
		cowsInfo.forEach(el => cow_ids.push(el.id))
		CattlesApiService.getTrimsByCowId(cow_ids)
			.then(res => {
				setTrims(res)
				setInitialTrims(res)
			})
			.catch(err => console.log(err))
			.finally(() => setTrimLoader(false))
	}, [])

	const removeCowInfoById = id => {
		setCowsInfo(prev => {
			const newState = prev.filter(el => el.id !== id)
			return newState
		})
	}

	const onChangeDatePicker = (date: Date | null, index: number) => {
		setTrims(prev => {
			const newState = [...prev]
			newState[index] = {
				...newState[index],
				target_date: moment(date).format('YYYY-MM-DD'),
			}
			return newState
		})
	}

	const changePersonForTrimById = (id, index) => {
		setTrims(prev => {
			const newState = [...prev]
			newState[index] = {
				...newState[index],
				responsible: {
					...newState[index].responsible,
					id: id,
				},
			}
			return newState
		})
	}
	const handleSubmit = () => {
		const promises = []
		setOnClickBtn(true)

		if (deleteTrimIds.length > 0) {
			deleteTrimIds.forEach(el => {
				promises.push(CattlesApiService.deleteTrimByTrimId(el))
			})
		}
		trims.forEach(trim => {
			const timestampMs = moment(trim.target_date, 'YYYY-MM-DD').valueOf()
			const respPersonId = trim.responsible.id

			if (trim.trim_ids) {
				//change trim
				trim.trim_ids.forEach(id => {
					promises.push(
						CattlesApiService.patchTrimByTrimId(id, timestampMs, respPersonId)
					)
				})
			} else {
				//create trim
				cowsInfo.forEach(cow => {
					promises.push(
						CattlesApiService.postTrimByCowId(cow.id, timestampMs, respPersonId)
					)
				})
			}
		})

		Promise.all(promises)
			.then(() => {
				dispatch(
					notifyUser({
						title: 'Данные успешно сохранены',
						description: 'Изменения применены.',
						isError: false,
					})
				)
				navigate('/cattles/trim', { replace: true })
			})
			.catch(() => {
				setOnClickBtn(false)
				dispatch(
					notifyUser({
						title: 'Ошибка! Данные не были сохранены',
						description: 'Попробуйте снова.',
						isError: true,
					})
				)
			})
	}

	const deleteTrim = (trim_ids, index) => {
		setTrims(prev => {
			const newState = prev.filter((_, idx) => idx !== index)
			return newState
		})
		trim_ids && setDeleteTrimIds(prev => [...prev, ...trim_ids])
	}

	// const addNewTrimObj = () => {
	// 	const obj = {
	// 		target_date: null,
	// 		responsible: {
	// 			id: null,
	// 		},
	// 		trim_ids: null,
	// 	}
	// 	setTrims(prev => [...prev, obj])
	// }
	const isDisableSubmitButton =
		cowsInfo.length === 0 ||
		trims.some(el => el.target_date === null || el.responsible.id === null) ||
		isEqualTrimStates

	return (
		<Box>
			<CardStandart sx={{ padding: '24px' }}>
				<HeaderCowQty cowQty={cowQty} />
				<TableContainer
					sx={{
						overflow: 'auto',
						maxHeight: '420px',
						'&::-webkit-scrollbar': {
							width: 4,
							height: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DBDBDB',
							borderRadius: 12,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#5222D0',
							borderRadius: 12,
						},
					}}
				>
					<Table stickyHeader sx={{ marginTop: '24px' }}>
						<TableHeadEditTrim />
						<TableBody>
							{cowsInfo?.length === 0 ? (
								<TableRow
									sx={{
										width: '100%',
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
											height: '200px',
										},
									}}
								>
									<TableCell colSpan={7} align={'center'} padding={'none'}>
										<TypographySecondary14>
											Нет выбранных дат обрезки
										</TypographySecondary14>
									</TableCell>
								</TableRow>
							) : (
								<TableBodyEditTrim
									cowsInfo={cowsInfo}
									removeCowInfoById={removeCowInfoById}
								/>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<EditTrimsBlock
					trims={trims}
					trimLoader={trimLoader}
					onChangeDatePicker={onChangeDatePicker}
					changePersonForTrimById={changePersonForTrimById}
					deleteTrim={deleteTrim}
				/>
				{/* <LargeCardButton
					onClick={() => addNewTrimObj()}
					title={'Добавить обрезку'}
					endAdornment={
						<PlusLogo
							stroke={COLORS.secondary}
							width={'24px'}
							height={'24px'}
						/>
					}
				/> */}
			</CardStandart>
			<Box
				display={'flex'}
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'flex-end'}
				mt={'16px'}
			>
				<ButtonCancel
					sx={{
						marginTop: '0px',
						width: '300px',
						height: '54px',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '17px',
					}}
					onClick={() => navigate('/cattles/trim')}
				>
					Выйти без сохранения
				</ButtonCancel>
				<ButtonUI
					disabled={isDisableSubmitButton}
					sx={{
						marginTop: '0px',
						width: '400px',
						height: '56px',
						fontWeight: 600,
						fontSize: '14px',
						lineHeight: '17px',
					}}
					onClick={handleSubmit}
				>
					Сохранить
				</ButtonUI>
			</Box>
			<ExitWarningModal
				open={showPrompt}
				onClose={cancelNavigation}
				onSave={() => setShowPrompt(false)}
				onDismiss={confirmNavigation}
				title={'Выйти без сохранения'}
				description={'Внесенные изменения будут утеряны.'}
				onSaveText={'Вернуться к редактированию'}
				onDismissText={'Выйти без сохранения'}
			/>
		</Box>
	)
}

export default TrimInfoList
