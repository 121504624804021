import { Box, Divider } from '@mui/material'
import InputWithoutRestrictions from 'components/shared/textFields/InputWithoutRestrictions'
import moment from 'moment'
import { Dispatch, SetStateAction, useState } from 'react'
import { COLORS } from 'styles/colors'
import GroupDate from 'ui/datePicker/GroupDate'
import SwitchiOS from 'ui/SwitchiOS'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { ICheckupList, IExaminersTrim } from '../models'

type TrimBlockProps = {
	trim: IExaminersTrim
	setCheckupInfo: Dispatch<SetStateAction<ICheckupList>>
}

const TrimBlock = (props: TrimBlockProps) => {
	const { trim, setCheckupInfo } = props
	const [selectDate, setSelectedDate] = useState(trim.target_date)
	const clickDatePicker = date => {
		setSelectedDate(date)
		setCheckupInfo(prev => {
			return {
				...prev,
				trim: {
					...prev.trim,
					target_date: moment(date).valueOf(),
				},
			}
		})
	}
	const onChangeComment = e => {
		const comment = e.target.value
		setCheckupInfo(prev => {
			return {
				...prev,
				trim: {
					...prev.trim,
					comment: comment,
				},
			}
		})
	}
	const toggleSwitch = () => {
		setCheckupInfo(prev => {
			return {
				...prev,
				trim: {
					...prev.trim,
					isTrim: !prev.trim.isTrim,
				},
			}
		})
	}
	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'row'}
				width={'100%'}
				alignItems={'flex-start'}
				gap={'40px'}
			>
				<Box width={'240px'}>
					<TypographyHeaderSecondary>
						Функциональная обрезка
						<br />
						всех копыт
					</TypographyHeaderSecondary>
				</Box>

				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={'16px'}
					width={'36%'}
				>
					<SwitchiOS
						checked={trim.isTrim}
						background={COLORS.health}
						onClick={toggleSwitch}
					/>
					<InputWithoutRestrictions
						value={trim.comment}
						onChange={e => onChangeComment(e)}
						isMultiline={true}
						label={'Комментарий'}
						width={'100%'}
						height={'100px'}
						rows={3}
						maxLength={140}
						isDisable={!trim.isTrim}
					/>
				</Box>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'row'}
				width={'100%'}
				alignItems={'flex-start'}
				gap={'40px'}
			>
				<Box width={'320px'}>
					<TypographyPrimary>Дата ближайшей обрезки</TypographyPrimary>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={'24px'}
					width={'100%'}
				>
					<Divider />
					<GroupDate
						value={selectDate}
						onChange={newDate => clickDatePicker(newDate)}
					/>
				</Box>
			</Box>
		</>
	)
}

export default TrimBlock
