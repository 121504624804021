import { ReactComponent as CrossCloseLogo } from 'assets/cross_close_logo.svg'
import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import FoodtableApiService from 'services/foodtableApiServices'
import Preloader from 'ui/Preloader'
import { IFoodtable } from '../../../models/IFeed'
import { COLORS } from '../../../styles/colors'
import { ChipItem } from '../../../ui/ChipItem'
import { tooltipForDashboardChart } from '../../group/TooltipsForCharts/tooltipsForCharts'

const FoodTableChart = () => {
	const [foodtableInfo, setFoodtableInfo] = useState<IFoodtable[]>([])
	const [activeBarnButton, setActiveBarnButton] = useState('all')
	const [initialFoodtableInfo, setInitialFoodtableInfo] = useState<
		IFoodtable[]
	>([])

	useEffect(() => {
		FoodtableApiService.getFoodtableReachAndUnreachInfo().then(response => {
			const data = response.data
			setFoodtableInfo(data)
			setInitialFoodtableInfo(data)
		})
	}, [])

	const emptyData = []
	const cameraIssueData = []
	const reachableData = []
	const unreachableData = []

	foodtableInfo?.forEach(item => {
		item.food_info.forEach(info => {
			cameraIssueData.push(info.camera_issue === true ? 100 : null)
			emptyData.push(info.reachable_percent <= 2 ? 100 : null)

			if (info.reachable_percent > 2) {
				reachableData.push(info.reachable_percent)
				unreachableData.push(info.unreachable_percent)
			}
		})
	})
	const totalData = foodtableInfo?.flatMap(item =>
		item.food_info.map(info => info.total_percent)
	)
	const labelData = foodtableInfo?.flatMap(item =>
		item.food_info.map(info => info.group_title)
	)
	const series = [
		{
			name: 'Нет данных',
			color: COLORS.icon,
			data: cameraIssueData,
		},
		{
			name: 'Нет корма',
			color: COLORS.error,
			data: emptyData,
		},
		{
			name: 'Недоступно',
			color: COLORS.health,
			data: unreachableData,
		},
		{
			name: 'Доступно',
			color: COLORS.success,
			data: reachableData,
		},
	]
	const seriesLength = cameraIssueData.length
	const minWidthForOneColumn = 5
	const maxWidthForManyColumns = 60
	const columnWidth =
		minWidthForOneColumn +
		maxWidthForManyColumns / (1 + 30 * Math.exp(-seriesLength / 3))

	const options: any = {
		colors: [COLORS.success, COLORS.health],
		mask: false,
		chart: {
			animations: {
				enabled: true,
				easing: 'easein',
				speed: 300,
				animateGradually: {
					enabled: false,
					delay: 0,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 300,
				},
			},
			zoom: {
				enabled: false,
			},
			stacked: true,
			height: 160,
			type: 'bar',
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			padding: {
				left: 0,
				right: 0,
			},
			show: true,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
		},

		plotOptions: {
			bar: {
				horizontal: false,
				borderRadius: 8,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth: `${columnWidth}%`,
				dataLabels: {
					hideOverflowingLabels: true,
				},
			},
		},
		fill: {
			opacity: 1,
		},
		xaxis: {
			crosshairs: {
				show: false,
			},
			type: 'category',
			tickPlacement: 'on',
			tickAmount: 31,
			tooltip: {
				enabled: false,
			},
			title: {
				text: 'Группы',
				style: {
					color: COLORS.icon,
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontWeight: 400,
				},
			},
			labels: {
				rotate: 0,
				trim: false,
				hideOverlappingLabels: false,
				datetimeUTC: false,
				style: {
					background: COLORS.icon,
					padding: '2px 4px',
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					colors: [COLORS.black],
				},
			},
			categories: labelData,
		},
		yaxis: {
			crosshairs: {
				show: false,
			},
			tickAmount: 2,
			max: 100,
			min: 0,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number) {
					if (value === 0) {
						return value
					} else {
						return value + '%'
					}
				},
			},
		},
		states: {
			normal: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			hover: {
				filter: {
					type: 'none',
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
					value: 0,
				},
			},
		},
		tooltip: {
			enable: true,
			intersect: true,
			shared: false,
			custom: ({ dataPointIndex }) => {
				return tooltipForDashboardChart(
					labelData,
					dataPointIndex,
					reachableData,
					unreachableData,
					totalData,
					emptyData,
					cameraIssueData
				)
			},
		},
		legend: {
			show: false,
		},
	}
	const handleActiveBarn = barnName => {
		setActiveBarnButton(barnName)
		filterChartData(barnName)
	}

	const handleDeleteBarn = () => {
		setActiveBarnButton('all')
		setFoodtableInfo(initialFoodtableInfo)
	}
	const filterChartData = barnName => {
		const filteredData = initialFoodtableInfo.filter(
			item => item.barn_title === barnName
		)
		setFoodtableInfo(filteredData)
	}

	return (
		<>
			{foodtableInfo.length === 0 ? (
				<div
					style={{ height: '255px', display: 'flex', justifyContent: 'center' }}
				>
					<Preloader />
				</div>
			) : (
				<>
					<div
						style={{
							marginLeft: '33px',
							marginTop: '16px',
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
						}}
					>
						{initialFoodtableInfo.map((el, index) => (
							<ChipItem
								onClick={() => handleActiveBarn(el.barn_title)}
								deleteIcon={
									activeBarnButton === el.barn_title ? (
										<CrossCloseLogo
											style={{ stroke: 'white', width: '16px', height: '16px' }}
										/>
									) : (
										<></>
									)
								}
								onDelete={() => handleDeleteBarn()}
								label={el.barn_title}
								key={index}
								sx={{
									height: '24px',
									background:
										activeBarnButton === el.barn_title
											? COLORS.main
											: COLORS.inputBorder,
									color: activeBarnButton === el.barn_title ? '#fff' : '#000',
								}}
							/>
						))}
					</div>
					<div style={{ marginLeft: '-20px' }} id={'dashboardChart'}>
						<style>
							{` #dashboardChart .apexcharts-bar-series > g > path:first-child, 
			#dashboardChart .apexcharts-xaxis-texts-g > text:first-child, 
			#dashboardChart .apexcharts-gridlines-vertical > line:first-child {
        ${'transform: translateX(1%);'}
      }`}
						</style>
						<ReactApexChart
							options={options}
							series={series}
							type='bar'
							height={200}
							className={'apex-chart'}
						/>
					</div>
				</>
			)}
		</>
	)
}

export default FoodTableChart
