import { ReactElement, useState } from 'react'
import { COLORS } from '../../styles/colors'
import { OutlinedButton } from './OutlinedButton'

interface ICustomButtonProps {
	title: string
	onClick?: (event) => void
	iconElement?: ReactElement
	colors?: string[]
	disable?: boolean
	titleColor?: string
	borderColor?: string
	bgActiveColor?: string
}

const CustomButton = (props: ICustomButtonProps) => {
	const {
		title,
		onClick,
		iconElement,
		colors,
		disable,
		titleColor = COLORS.main,
		borderColor = COLORS.main,
		bgActiveColor = COLORS.main,
	} = props
	const [click, setClick] = useState(false)

	return (
		<OutlinedButton
			onClick={onClick}
			onMouseUp={() => setClick(false)}
			onMouseDown={() => setClick(true)}
			disableRipple
			disabled={disable}
			startIcon={iconElement}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: 'max-content',
				padding: '16px',
				margin: '0px',
				height: '32px',
				color: titleColor,
				border: `1px solid ${borderColor}`,
				textTransform: 'none',
				fontWeight: 600,
				'.MuiButton-startIcon': {
					fill: colors ? (click ? colors[0] : colors[1]) : 'none',
					stroke: colors ? (click ? colors[1] : colors[0]) : 'none',
				},
				'&:hover': {
					backgroundColor: 'transparent',
					color: titleColor,
					borderColor: borderColor,
					boxShadow: 'none',
				},
				'&:active': {
					backgroundColor: bgActiveColor,
					color: 'white',
					borderColor: borderColor,
					boxShadow: 'none',
				},
				'&:disabled': {
					backgroundColor: 'transparent',
					color: COLORS.inputBorder,
					borderColor: COLORS.inputBorder,
				},
			}}
		>
			{title}
		</OutlinedButton>
	)
}

export default CustomButton
