import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IConstants, IInitialStore } from 'models/IConstants'
import ConstantsApiService from 'services/constantsApiService'

export const getConstantsList = createAsyncThunk(
	'constants',
	async function (_, { rejectWithValue }) {
		try {
			const response = await ConstantsApiService.getConstants()

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data as IConstants
		} catch (error) {
			let errorMessage = 'Failed to retrive groups!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

const initialState: IInitialStore = {
	constants: undefined,
	isLoadingConstants: false,
	errorConstantsMessage: undefined,
}

export const constantsSlice = createSlice({
	name: 'constants',
	initialState,
	reducers: {},
	extraReducers: {
		[getConstantsList.fulfilled.type]: (
			state,
			action: PayloadAction<IConstants>
		) => {
			state.isLoadingConstants = false
			state.errorConstantsMessage = null
			state.constants = action.payload
		},
		[getConstantsList.pending.type]: state => {
			state.isLoadingConstants = true
		},
		[getConstantsList.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.isLoadingConstants = false
			state.errorConstantsMessage = action.payload
		},
	},
})

export default constantsSlice.reducer
