import 'antd/dist/antd.min.css'
import locale from 'antd/es/date-picker/locale/ru_RU'
import './groupDateStyle.css'

import { COLORS } from '../../styles/colors'
import { antdMonths } from './ruLocalization'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as CalendarMainLogo } from '../../assets/calendar_main_logo.svg'

import { DatePicker } from 'antd'
import { DateRestriction } from 'models/components'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'

interface IGroupDateProps {
	onChange?: any
	value?: Date | string | number
	restrictionType?: DateRestriction
	blockedDatesRange?: [Moment, Moment]
}

export default function GroupDate(props: IGroupDateProps) {
	const [feedPlanDate, setFeedPlanDate] = useState<Moment | null>(
		props.value ? moment(props.value) : null
	)
	const [color, setColor] = useState(COLORS.icon)
	const [dateFormat, setDateFormat] = useState('')

	moment.locale('ru', antdMonths)

	const nowDate = new Date(Date.now()).toLocaleDateString()
	const yesterdayDate = new Date(Date.now() - 86400000).toLocaleDateString()

	useEffect(() => {
		setFeedPlanDate(props.value ? moment(props.value) : null)
		if (moment(props.value).format('L') === nowDate) {
			setDateFormat('[Cегодня, ]DD.MM.YYYY')
		} else if (moment(props.value).format('L') === yesterdayDate) {
			setDateFormat('[Вчера, ]DD.MM.YYYY')
		} else {
			setDateFormat('DD.MM.YYYY')
		}
	}, [props.value])

	const onChangeHandler = (date: Moment | null) => {
		props.onChange?.(date)
	}

	const disabledDate = (currentDate: Moment | null) => {
		if (!currentDate) return false

		if (props.restrictionType === DateRestriction.PAST) {
			return currentDate.isAfter(moment().endOf('day'))
		}
		if (props.restrictionType === DateRestriction.FUTURE) {
			return currentDate.isBefore(moment().startOf('day'))
		}

		if (props.blockedDatesRange) {
			const [start, end] = props.blockedDatesRange
			return currentDate.isBetween(start, end, 'day', '[]')
		}

		return false
	}

	return (
		<DatePicker
			defaultValue={props.value ? moment(props.value) : feedPlanDate}
			onMouseEnter={() => setColor(COLORS.main)}
			onMouseLeave={() => setColor(COLORS.icon)}
			suffixIcon={
				<div style={{ position: 'relative' }}>
					<CalendarMainLogo stroke={color} />
					<KeyboardArrowDownIcon
						sx={{
							position: 'absolute',
							top: '2px',
							left: '180px',
							color: color,
							transform: 'none',
						}}
					/>
				</div>
			}
			onChange={onChangeHandler}
			value={feedPlanDate}
			className='groupDate'
			placeholder={'Выберите дату'}
			locale={locale}
			format={dateFormat}
			showToday={false}
			clearIcon={null}
			disabledDate={disabledDate}
		/>
	)
}
