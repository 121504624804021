import moment from 'moment'
import CattlesApiService from 'services/cattlesApiServices'
import { changeIdentifiedCowsQty, changeTagNumber } from 'store/cattlesSlice'
import { notifyUser } from 'store/notificationsSlice'

export const submitCowFormInfo = async (
	isNeedSaveTagPicture: boolean,
	isNeedSaveTagNumber: boolean,
	cowId: number,
	tagImage: File | string,
	tagNumber: number,
	setErrorMessages: any,
	dispatch: any,
	cameraId: number,
	isIdentificatedCow: boolean
) => {
	const objForSend = {
		cow_id: cowId,
		tag_id: tagNumber,
		timestamp: moment().valueOf(),
		angle: 'tag',
		image: tagImage,
	}

	try {
		if (isNeedSaveTagNumber) {
			await CattlesApiService.patchCowTagInfo(objForSend)
				.then(response => {
					dispatch(changeTagNumber(response.data))
				})
				.catch(error => {
					setErrorMessages(error.response.data.tag_id)
					throw new Error(error.response.data.tag_id)
				})
		}

		if (isNeedSaveTagPicture) {
			await CattlesApiService.postCowTagInfo(objForSend)
		}

		const response = await CattlesApiService.postComfirmDigitizatedCounter(
			cameraId
		)
		const newCount = response?.data?.counter

		dispatch(changeIdentifiedCowsQty(newCount))
		isIdentificatedCow
			? dispatch(
					notifyUser({
						title: 'Данные успешно сохранены',
						isError: false,
					})
			  )
			: dispatch(
					notifyUser({
						title: 'Корова оцифрована',
						isError: false,
					})
			  )
	} catch (e) {
		dispatch(
			notifyUser({
				title: 'Ошибка! Данные не были сохранены',
				description: 'Попробуйте снова.',
				isError: true,
			})
		)
	}
}
