import { Box, Dialog, Divider } from '@mui/material'
import useIsEqual from 'hook/useIsEqual'
import { ICowsList, ITrims } from 'models/ICattlesData'
import moment from 'moment'
import { Dispatch, SetStateAction, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { COLORS } from 'styles/colors'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { ReactComponent as CrossCloseLogo } from '../../../../../assets/cross_close_logo.svg'
import { ICheckupList } from '../models'
import DefectiveMilkBlock from './DefectiveMilkBlock'
import DiagnosticBlock from './DiagnosticBlock'
import DisposeRecommendedBlock from './DisposeRecommendedBlock'
import MainInfoBlock from './MainInfoBlock'
import NextExaminationBlock from './NextExaminationBlock'
import TrimBlock from './TrimBlock'
import ViewHistoryBlock from './ViewHistoryBlock'

type ModalProps = {
	openModal: boolean
	cowInfo: ICowsList
	onClose: () => void
	callbackFn?: Dispatch<SetStateAction<any>>
	treatmentInfo?: ITrims
	trimData?: {
		isTrim: boolean
		comment: string
		target_date: number
		responsible_id?: undefined
	}
}

const CheckupCowModal = (props: ModalProps) => {
	const location = useLocation()
	const { openModal, cowInfo, onClose, trimData, treatmentInfo, callbackFn } =
		props

	const initialCheckupBlock: ICheckupList = {
		diagnosis: treatmentInfo?.closest_examination?.diagnosis
			? treatmentInfo.closest_examination?.diagnosis
			: [],
		next_examination_date: moment().add(3, 'days').valueOf(),
		milk_reject_start: treatmentInfo?.closest_examination?.milk_reject_start
			? treatmentInfo.closest_examination?.milk_reject_start
			: undefined,
		milk_reject_end: treatmentInfo?.closest_examination?.milk_reject_end
			? treatmentInfo.closest_examination?.milk_reject_end
			: undefined,
		dispose_recommended: treatmentInfo?.closest_examination?.dispose_recommended
			? treatmentInfo.closest_examination?.dispose_recommended
			: false,
		trim: {
			isTrim: trimData ? trimData.isTrim : false,
			comment: trimData ? trimData.comment : '',
			target_date: trimData ? trimData.target_date : undefined,
			responsible_id: undefined,
		},
	}
	const [openConfirmationExitWindow, setOpenConfirmationExitWindow] =
		useState<boolean>(false)
	const [checkupInfo, setCheckupInfo] =
		useState<ICheckupList>(initialCheckupBlock)
	const dispatch = useAppDispatch()
	const { userData } = useAppSelector(state => state.userReducer)
	const isEqualState = useIsEqual(initialCheckupBlock, checkupInfo)

	const submitForm = () => {
		const promises = []
		const updateData = {
			...checkupInfo,
			milk_reject_end: checkupInfo.milk_reject_end
				? checkupInfo.milk_reject_end
				: null,
			milk_reject_start: checkupInfo.milk_reject_start
				? checkupInfo.milk_reject_start
				: null,
			diagnosis: checkupInfo.diagnosis.map(el => ({
				...el,
				sickness_id: el.sickness.map(sickness => sickness.id),
			})),
		}

		promises.push(
			CattlesApiService.postCheckupCowInfoByCowId(cowInfo.id, updateData)
		)
		if (
			checkupInfo.trim.isTrim &&
			location.pathname !== '/cattles/trim/check-up'
		) {
			promises.push(
				CattlesApiService.postTrimByCowId(
					cowInfo.id,
					checkupInfo.trim.target_date,
					userData.id,
					checkupInfo.trim.comment
				)
			)
		}
		Promise.all(promises)
			.then(() => {
				callbackFn &&
					callbackFn(prev => {
						return {
							...prev,
							isChangedTreatment: true,
							comment: checkupInfo.trim.comment,
							nextTrimDate: moment(checkupInfo.trim.target_date).format(
								'YYYY-MM-DD'
							),
							treatment: {
								...prev.treatment,
								closest_examination: {
									...prev.treatment.closest_examination,
									diagnosis: checkupInfo.diagnosis,
									milk_reject_start: checkupInfo.milk_reject_start,
									milk_reject_end: checkupInfo.milk_reject_end,
									dispose_recommended: checkupInfo.dispose_recommended,
								},
							},
						}
					})
				onClose()
				setCheckupInfo(initialCheckupBlock)
				dispatch(
					notifyUser({
						title: `Осмотр коровы ${cowInfo.tag_id} завершён.`,
						description: 'Данные успешно сохранены.',
						isError: false,
					})
				)
			})
			.catch(error => {
				dispatch(
					notifyUser({
						title: `Осмотр коровы ${cowInfo.tag_id} завершён с ошибкой.`,
						description: error.message,
						isError: true,
					})
				)
			})
	}

	const closeDialogWindow = () => {
		if (isEqualState) {
			onClose()
			setCheckupInfo(initialCheckupBlock)
		} else {
			setOpenConfirmationExitWindow(true)
		}
	}
	return (
		<>
			{openConfirmationExitWindow ? (
				<Dialog
					scroll='paper'
					fullWidth={true}
					maxWidth={'sm'}
					onClose={closeDialogWindow}
					open={openConfirmationExitWindow}
					aria-labelledby='checkup-dialog-confirmation-form'
					PaperProps={{
						style: {
							borderRadius: '12px',
							padding: '32px 24px',
							boxShadow: 'none',
							height: 'max-content',
						},
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '24px',
						}}
					>
						<TypographyHeader>Выйти без сохранения</TypographyHeader>
						<TypographyPrimary>
							Внесенные изменения будут утеряны.{' '}
						</TypographyPrimary>
						<Box
							display={'flex'}
							flexDirection={'row'}
							width={'100%'}
							gap={'24px'}
						>
							<OutlinedButton
								disableRipple
								sx={{ m: 0, width: '100%' }}
								onClick={() => setOpenConfirmationExitWindow(false)}
							>
								Вернуться к <br />
								редактированию
							</OutlinedButton>
							<OutlinedButton
								disableRipple
								sx={{
									m: 0,
									width: '100%',
									color: COLORS.deviationFont,
									border: `1px solid ${COLORS.deviationFont}`,
									'&:hover': {
										color: COLORS.error,
										opacity: 0.8,
										backgroundColor: 'transparent',
										boxShadow: 'none',
									},
								}}
								onClick={() => {
									onClose()
									setCheckupInfo(initialCheckupBlock)
								}}
							>
								Выйти без сохранения
							</OutlinedButton>
						</Box>
					</Box>
				</Dialog>
			) : (
				<Dialog
					scroll='paper'
					fullWidth={true}
					maxWidth={'lg'}
					onClose={closeDialogWindow}
					open={openModal}
					aria-labelledby='checkup-dialog-form'
					sx={{ padding: '24px', margin: '0px' }}
					PaperProps={{
						style: {
							borderRadius: '12px',
							padding: '32px 24px',
							boxShadow: 'none',
							height: 'max-content',
						},
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<TypographyHeader>
								{'Осмотр коровы ' + cowInfo.tag_id}
							</TypographyHeader>
							<CrossCloseLogo
								style={{ cursor: 'pointer', stroke: COLORS.icon }}
								onClick={closeDialogWindow}
							/>
						</Box>
						<MainInfoBlock cowInfo={cowInfo} />
						<Divider />
						<ViewHistoryBlock cowId={cowInfo.id} />
						<Divider />
						<DiagnosticBlock
							diagnosisInfo={checkupInfo.diagnosis}
							setCheckupInfo={setCheckupInfo}
							disposeRecommended={checkupInfo.dispose_recommended}
						/>
						<Divider />
						<NextExaminationBlock
							date={checkupInfo.next_examination_date}
							setCheckupInfo={setCheckupInfo}
						/>
						<Divider />
						<DefectiveMilkBlock
							startOfReject={checkupInfo.milk_reject_start}
							endOfReject={checkupInfo.milk_reject_end}
							setCheckupInfo={setCheckupInfo}
						/>
						<Divider />
						<TrimBlock
							trim={checkupInfo.trim}
							setCheckupInfo={setCheckupInfo}
						/>
						<Divider />
						<DisposeRecommendedBlock
							disposeRecommended={checkupInfo.dispose_recommended}
							setCheckupInfo={setCheckupInfo}
							diagnosisInfo={checkupInfo.diagnosis}
						/>
						<ButtonUI
							sx={{
								marginTop: '0px',
								maxWidth: '400px',
								height: '56px',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '17px',
								alignSelf: 'center',
							}}
							onClick={submitForm}
						>
							Сохранить
						</ButtonUI>
					</Box>
				</Dialog>
			)}
		</>
	)
}

export default CheckupCowModal
