import { Box } from '@mui/material'
import TrimInfoList from './ui/TrimInfoList'

const TrimEditPage = () => {
	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<TrimInfoList />
		</Box>
	)
}

export default TrimEditPage
