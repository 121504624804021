import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import React from 'react'
import { COLORS } from 'styles/colors'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

interface ExitWarningModalProps {
	open: any
	onClose: any
	onSave: any
	onDismiss: any
	title: string
	description?: string
	onSaveText: string
	onDismissText: string
}

const ExitWarningModal: React.FC<ExitWarningModalProps> = ({
	open,
	onClose,
	onSave,
	onDismiss,
	title,
	description,
	onSaveText,
	onDismissText,
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby='exit-warning-dialog-title'
			aria-describedby='exit-warning-dialog-description'
			PaperProps={{
				style: {
					borderRadius: '12px',
					padding: '32px 24px',
					boxShadow: 'none',
					width: '650px',
				},
			}}
		>
			<Box
				sx={{
					textAlign: 'center',
				}}
			>
				<TypographyHeader id='exit-warning-dialog-title'>
					{title}
				</TypographyHeader>
				<TypographyPrimary
					sx={{ marginTop: '12px' }}
					id='exit-warning-dialog-description'
				>
					{description}
				</TypographyPrimary>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: '24px',
						marginTop: '16px',
					}}
				>
					<OutlinedButton sx={{ margin: '0px' }} onClick={onSave} fullWidth>
						{onSaveText}
					</OutlinedButton>
					<OutlinedButton
						sx={{
							margin: '0px',
							borderColor: COLORS.error,
							color: COLORS.error,
							'&:hover': {
								opacity: 0.7,
								backgroundColor: 'transparent',
								color: COLORS.error,
								boxShadow: 'none',
							},
						}}
						onClick={onDismiss}
						fullWidth
					>
						{onDismissText}
					</OutlinedButton>
				</Box>
			</Box>
		</Dialog>
	)
}

export default ExitWarningModal
