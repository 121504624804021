import { Box, Button } from '@mui/material'
import { ReactComponent as CameraLogo } from 'assets/camera_group_logo.svg'
import { ReactComponent as ArrowNext } from 'assets/fill_back_arrow.svg'
import { ReactComponent as MenuIconCattles } from 'assets/icons/menu_cattles.svg'
import CustomTooltip from 'components/shared/CustomTooltip'
import { CameraType, ICameraListItem } from 'models/IFrameInfo'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import CameraApiService from 'services/cameraApiServices'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import DigitizatedModal from '../shared/DigitizatedModal'

const tooltipContent = 'Доступный пакет согласно вашей лицензии.'

const DigitizationWidjet = props => {
	const { digitizated } = props
	const navigate = useNavigate()
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [cameraList, setCameraList] = useState<ICameraListItem[]>(undefined)
	const [fetchCameraList, setFetchCameraList] = useState<boolean>(false)
	useEffect(() => {
		setFetchCameraList(true)
		CameraApiService.getCameraList(CameraType.Identification)
			.then(res => setCameraList(res))
			.catch(err => console.error(err.message))
			.finally(() => setFetchCameraList(false))
	}, [])
	const onClickDigitBtn = () => {
		if (cameraList.length === 1) {
			navigate(`/cattles/digitization/${cameraList[0].id}`, {
				state: { cameraList },
			})
		} else {
			setOpenModal(true)
		}
	}
	const disableDigitBtn = cameraList?.length === 0 || fetchCameraList
	return (
		<CardStandart sx={{ padding: '24px' }}>
			<Box
				display={'flex'}
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Box>
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						gap={'4px'}
					>
						<MenuIconCattles fill={COLORS.secondary} />
						{digitizated ? (
							<Box
								display={'flex'}
								flexDirection={'column'}
								alignItems={'left'}
								gap={'4px'}
							>
								<Box
									display={'flex'}
									flexDirection={'row'}
									alignItems={'center'}
									gap={'4px'}
								>
									<TypographyPrimary16Bold>
										Оцифровано {digitizated.value}
									</TypographyPrimary16Bold>
									<TypographySecondary>
										{'/' + digitizated.out_of}
									</TypographySecondary>
									<CustomTooltip
										placement={'top'}
										width={'18px'}
										height={'18px'}
										info={true}
										title={tooltipContent}
									/>
								</Box>
								<TypographySecondary>
									Не опознано{' '}
									{digitizated?.unidentified && digitizated.unidentified}
								</TypographySecondary>
							</Box>
						) : (
							<Skeleton width={200} height={34} />
						)}
					</Box>
				</Box>
				{disableDigitBtn ? (
					<Skeleton width={200} height={34} />
				) : (
					<Button
						onClick={onClickDigitBtn}
						disableRipple={true}
						disabled={disableDigitBtn}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '6px',
							textTransform: 'none',
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					>
						<CameraLogo stroke={COLORS.main} />
						<TypographyPrimary16Bold sx={{ color: COLORS.main }}>
							Оцифровать
						</TypographyPrimary16Bold>
						<ArrowNext
							style={{ transform: 'rotate(180deg)' }}
							stroke={COLORS.main}
						/>
					</Button>
				)}
			</Box>
			<DigitizatedModal
				cameraList={cameraList}
				open={openModal}
				onCloseModal={() => setOpenModal(false)}
			/>
		</CardStandart>
	)
}

export default DigitizationWidjet
