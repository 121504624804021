import { ThemeProvider } from '@emotion/react'
import { Box, InputAdornment, OutlinedInput, useTheme } from '@mui/material'
import { morphForWords, roundingNumber } from 'components/shared/processes'
import React from 'react'
import { customTheme } from 'ui/buttons/ButtonStyle'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
const TrimContentBlock = props => {
	const { lastTrim, availableLastTrimValues, handleDayChange } = props
	const outerTheme = useTheme()
	return (
		<>
			<TypographyPrimary>Количество дней с последней обрезки</TypographyPrimary>
			<ThemeProvider theme={customTheme(outerTheme)}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'12px'}
					marginTop={'16px'}
					flexWrap={'wrap'}
				>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						error={
							lastTrim.startOf < 0 || lastTrim.startOf > lastTrim.endOf
								? true
								: false
						}
						placeholder={
							availableLastTrimValues?.startOf
								? `${availableLastTrimValues.startOf} ${morphForWords(
										availableLastTrimValues.startOf,
										['день', 'дня', 'дней']
								  )}`
								: 'Дни'
						}
						value={lastTrim.startOf ? roundingNumber(lastTrim.startOf, 0) : ''}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(lastTrim.startOf, 0)?.toString().length <= 2
											? '25px'
											: roundingNumber(lastTrim.startOf, 0)?.toString()
													.length <= 4
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{lastTrim.startOf ? (
									<TypographySecondary>от</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'lastTrim', 'startOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>

					<TypographySecondary>-</TypographySecondary>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						error={
							lastTrim.endOf < 0 || lastTrim.endOf < lastTrim.startOf
								? true
								: false
						}
						placeholder={
							availableLastTrimValues?.endOf
								? `${availableLastTrimValues.endOf} ${morphForWords(
										availableLastTrimValues.endOf,
										['день', 'дня', 'дней']
								  )}`
								: 'Дни'
						}
						value={lastTrim.endOf ? roundingNumber(lastTrim.endOf, 0) : ''}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(lastTrim.endOf, 0)?.toString().length <= 2
											? '25px'
											: roundingNumber(lastTrim.endOf, 0)?.toString().length <=
											  4
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{lastTrim.endOf ? (
									<TypographySecondary>до</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'lastTrim', 'endOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>
				</Box>
			</ThemeProvider>
		</>
	)
}

export default TrimContentBlock
