export interface IFrameViewer {
	openFrameViewer?: boolean
	toggleSwitch?: () => void
	visibleMask?: boolean
	setVisibleMask?: React.Dispatch<React.SetStateAction<boolean>>
	group_title?: string
	setOpenFrameViewer?: (open: boolean) => void
	objectForCamera: IObjectFrameViwer
}
export interface IObjectFrameViwer {
	id: number
	start_dt: number
	density: number
	r_feed: number
	end_dt: number
	t_feed: number
	ur_feed: number
}

export interface ICameraListItem {
	id: number
	key: string
	title: string
	group: string
	milking_hall: string
}

export enum CameraType {
	Identification = 'identification',
	Foogtable = 'foodtable',
}
