import { Box } from '@mui/material'
import { morphForWords } from 'components/shared/processes'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { IDonutDescriptionProps } from '../utils/models'

const DonutDescription = (props: IDonutDescriptionProps) => {
	const { description } = props
	return (
		<Box sx={{ width: '50%' }}>
			{description.map((el, index) => (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
					key={index}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '4px',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}
					>
						<span
							style={{
								borderRadius: '50%',
								width: '8px',
								height: '8px',
								backgroundColor: el.color,
							}}
						>
							&nbsp;
						</span>

						<TypographySecondary
							sx={{
								textAlign: 'right',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{el.label}
						</TypographySecondary>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '4px',
						}}
					>
						<TypographyPrimary sx={{ textAlign: 'right' }}>
							{el.value}
						</TypographyPrimary>
						<TypographySecondary
							sx={{
								textAlign: 'right',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{morphForWords(el.value, ['голова', 'головы', 'голов'])}
						</TypographySecondary>
					</Box>
				</Box>
			))}
		</Box>
	)
}

export default DonutDescription
