export type ColumnTypes = { title: string; isShowColumn: boolean }
export enum LamenessStatus {
	One = '1',
	Two = '2',
	Three = '3',
	Four = '4',
	Five = '5',
}

export enum TreatmentStatus {
	NotPrescribed = 'not_prescribed',
	Suspended = 'suspended',
	Treated = 'treated',
	Completed = 'completed',
	NotRequired = 'not_required',
	DisposeRecommended = 'dispose_recommended',
}

export enum InspectionStatus {
	Required = 'required',
	Planned = 'planned',
	Expired = 'expired',
	Completed = 'completed',
	NotPlanned = 'not_planned',
}

export enum LactationStatus {
	Lactating = 'lactating',
	NonLactating = 'non_lactating',
	DefectiveMilk = 'defective_milk',
	CommercialMilk = 'commercial_milk',
}

export enum LamenessTypeChart {
	LamenessType = 'lameness',
	PointType = 'point',
}

export interface IFiltersLamenessTable {
	tags: number[]
	lameness: {
		statuses: LamenessStatus[]
		startOf: number | string
		endOf: number | string
	}
	treatmentStatus: {
		statuses: TreatmentStatus[]
		startOf: number | string
		endOf: number | string
	}
	inspectionStatus: {
		statuses: InspectionStatus[]
		startOf: number | undefined
		endOf: number | undefined
	}
	diagnoses: {
		ids: number[]
	}
	cowAge: {
		startOf: number | string
		endOf: number | string
	}
	groups: {
		ids: number[]
	}
	lactation: {
		statuses: LactationStatus[]
		startOf: number | string
		endOf: number | string
	}
	fatness: IFatness
	examiners: {
		ids: number[]
	}
	lastTrim: {
		startOf: number | string
		endOf: number | string
	}
	responsiblePersons: {
		ids: number[]
	}
	isLateTrims: boolean
	dateRange?: {
		startDate: number
		endDate: number
	}
}

export interface ILameness {
	key: LamenessStatus
	title: string
	id: number
}

export interface ITreatment {
	key: TreatmentStatus
	title: string
	id: number
}

export interface IInspection {
	key: InspectionStatus
	title: string
	id: number
}

export interface ILactation {
	key: LactationStatus
	title: string
	id: number
}
export interface IFatness {
	startOf: number | ''
	endOf: number | ''
}
export enum PositionHoof {
	Front_Left = 'front_left',
	Front_Right = 'front_right',
	Rear_Left = 'rear_left',
	Rear_Right = 'rear_right',
	All = 'all',
}

export interface ICheckupList {
	diagnosis: IDiagnosisBlock[]
	next_examination_date: number | null
	milk_reject_start: number | null
	milk_reject_end: number | null
	dispose_recommended: boolean
	id?: number
	created_dt?: number
	examiner?: IExaminersHistoryResponse
	limpness?: number
	trim: IExaminersTrim
}

export interface IExaminersTrim {
	isTrim: boolean
	target_date: number | undefined
	responsible_id: number | undefined
	done_dt?: number
	comment: string
}

interface IExaminersHistoryResponse {
	id: number
	firstname: string
	lastname: string
	position: string
}

export interface IDiagnosisBlock {
	position: PositionHoof
	sickness?: IDiagnosesResponse[]
	sickness_id?: number[]
	comment: string
}

export interface IExaminersResponse {
	id: number
	name: string
	position: string
}
export interface IDiagnosesRes {
	items: IDiagnosesResponse[]
}
export interface IDiagnosesResponse {
	id: number
	title: string
	group: string
}
