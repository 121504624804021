const isSmallScreen = window.innerWidth < 780
const userAgent = navigator.userAgent.toLowerCase()

export const isMobile =
	/webos|ipod|iphone|blackberry|iemobile|opera mini/i.test(userAgent)

export const isTablet =
	/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
		userAgent
	)
export const drawerWidth = isTablet || isMobile || isSmallScreen ? 70 : 230
export const serverName = process.env.REACT_APP_SERVER_NAME
