import { Box, Grid, Typography } from '@mui/material'
import OkIcon from 'assets/okVerifyIcon'
import { CardProps } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardStandart } from 'ui/CardStandart'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import axAPI from '../../../http'
import FeedPlanElement from '../shared/FeedPlanElement'

const PlanCardWidjet = ({ title }: CardProps) => {
	const [testPlanData, setTestPlanData] = useState<any[]>()
	const [feedPlanDate, setFeedPlanDate] = useState<Date | null>(new Date())
	const navigate = useNavigate()
	useEffect(() => {
		axAPI
			.get(`feedplans/${feedPlanDate.toISOString().slice(0, 10)}`)
			.then(resonse => setTestPlanData(resonse.data))
	}, [feedPlanDate])

	if (!testPlanData) {
		return <SkeletonEmptyCard height={'244px'} needTitle heightTitle={'24px'} />
	}
	const now = new Date()
	const hours = now.getHours().toString().padStart(2, '0')
	const minutes = now.getMinutes().toString().padStart(2, '0')
	const seconds = now.getSeconds().toString().padStart(2, '0')

	const timeString: string = `${hours}:${minutes}:${seconds}`

	const filteredListAfterCurrentTime = testPlanData.filter(
		el => el.time > timeString
	)

	const getDisplayedEvents = planData => {
		const toDisplay = []

		const currentDate = new Date()
		const currentHr = currentDate.getHours()
		const currentValue = currentHr * 60 + currentDate.getMinutes()

		planData.forEach(event => {
			let targetValue =
				Number(event.time.split(':')[0]) * 60 + Number(event.time.split(':')[1])

			if (event.time.split(':')[0] > currentHr) {
				toDisplay.push(event)
			} else if (targetValue >= currentValue) {
				toDisplay.push(event)
			}
		})
		return toDisplay
	}

	const isActiveEvent = (eventId, time) => {
		const event = testPlanData.find(el => el.id === eventId)
		if (!event) return false

		const currentDate = new Date()
		const currentValue = currentDate.getHours() * 60 + currentDate.getMinutes()
		const targetValue =
			Number(time.split(':')[0]) * 60 + Number(time.split(':')[1])

		return (
			currentValue >= targetValue &&
			currentValue <= targetValue + event.deviation
		)
	}
	const planData = []
	let currentTime = undefined
	let currentEvent = undefined

	getDisplayedEvents(testPlanData).forEach((plan, index) => {
		if (currentTime !== plan.time || currentEvent !== plan.type) {
			planData.push(plan)
			currentTime = plan.time
			currentEvent = plan.event
		}
	})

	return (
		<CardStandart
			onClick={() => navigate('/feed/plan')}
			sx={{
				cursor: 'pointer',
				width: '100%',
				height: '324px',
				padding: '24px',
			}}
		>
			<TypographyHeader sx={{ marginBottom: '16px' }}>{title}</TypographyHeader>

			{planData.length === 0 ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '15px',
					}}
				>
					<Typography
						sx={{
							fontSize: 14,
							fontWeight: 400,
							color: '#7F7F84',
						}}
					>
						Все задачи на сегодня выполнены
					</Typography>
					<OkIcon width={'24px'} height={'24px'} />
				</Box>
			) : (
				<Grid container direction='column' spacing={2}>
					{planData.slice(0, 4).map(plan => {
						return (
							<Grid item key={plan.id}>
								<FeedPlanElement
									isProcessing={isActiveEvent(plan.event, plan.time)}
									timeBegin={plan.time}
									title={plan.type}
								/>
							</Grid>
						)
					})}
				</Grid>
			)}

			{filteredListAfterCurrentTime.length > 4 ? (
				<Typography
					sx={{
						fontSize: 14,
						fontWeight: 400,
						color: '#7F7F84',
						display: 'flex',
						marginTop: '8px',
					}}
				>
					{`+ еще ${filteredListAfterCurrentTime.length - 4}`}
				</Typography>
			) : (
				<></>
			)}
		</CardStandart>
	)
}

export default PlanCardWidjet
