import { Box, tableCellClasses, TableCellProps, TableHead } from '@mui/material'
import { ReactComponent as TagNumberIcon } from 'assets/icons/tag-icon.svg'
import { COLORS } from 'styles/colors'
import CustomTableRow from 'ui/table/CustomTableRow'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const TableHeadEditTrim = () => {
	const tableHead = {
		rowKey: 'tableTrimViewHead',
		rowBorderBottom: COLORS.secondaryFontOpacity,
		height: '40px',
		cellItems: [
			{
				width: '7%',
				children: (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '4px',
						}}
					>
						<TagNumberIcon />
						<TypographySecondary>
							Номер <br /> бирки
						</TypographySecondary>
					</Box>
				),
			},
			{
				width: '20%',
				children: <TypographySecondary>Группа</TypographySecondary>,
			},
			{
				width: '20%',
				children: (
					<TypographySecondary>
						Хромота <br />
						Продолжительность
					</TypographySecondary>
				),
			},
			{
				width: '17%',
				children: (
					<TypographySecondary>
						Дата последней
						<br />
						обрезки
					</TypographySecondary>
				),
			},
			{
				width: '13%',
				children: (
					<TypographySecondary>
						Дата следующей
						<br />
						обрезки
					</TypographySecondary>
				),
			},
			{
				width: '13%',
				children: <TypographySecondary>Исполнитель</TypographySecondary>,
			},
			{
				width: '5%',
				padding: '0px 6px 0px 0px',
				children: <TypographySecondary>Действие</TypographySecondary>,
				align: 'right' as TableCellProps['align'],
			},
		],
	}

	return (
		<TableHead
			sx={{
				[`& .${tableCellClasses.root}`]: {
					backgroundColor: '#fff',
				},
			}}
		>
			<CustomTableRow
				rowKey={tableHead.rowKey}
				cellItems={tableHead.cellItems}
				rowBorderBottom={tableHead.rowBorderBottom}
				rowHeight={tableHead.height}
			/>
		</TableHead>
	)
}
export default TableHeadEditTrim
