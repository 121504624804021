import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import { useEffect } from 'react'
import { fetchLightGroupList } from 'store/groupsSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import Preloader from 'ui/Preloader'

const GroupsContentBlock = props => {
	const { setActiveFilters, groups } = props
	const dispatch = useAppDispatch()
	const { lightGroupList, loading } = useAppSelector(
		state => state.groupsReducer
	)
	useEffect(() => {
		!lightGroupList && dispatch(fetchLightGroupList())
	}, [])

	const availableGroupsArray = lightGroupList?.map(el => ({
		id: el.id,
		title: el.title,
	}))
	return (
		<>
			{loading || !lightGroupList ? (
				<Preloader />
			) : (
				<AutocompleteInput
					widthForInput={'100%'}
					onClick={id => {
						setActiveFilters(prev => {
							const ids = prev.groups.ids
							const isPresent = ids.includes(id)
							const updatedIds = isPresent
								? ids.filter(item => item !== id)
								: [...ids, id]

							return {
								...prev,
								groups: {
									...prev.groups,
									ids: updatedIds,
								},
							}
						})
					}}
					isCheckbox
					availableList={availableGroupsArray}
					selectedValues={groups.ids}
					label={'Группа'}
					limitTags={2}
					needChip
					loading={loading}
				/>
			)}
		</>
	)
}

export default GroupsContentBlock
