import { createSlice } from '@reduxjs/toolkit'

type NotificationProps = {
	open: boolean
	handleClose?: () => void
	isError?: boolean
	isWarning?: boolean
	title: string
	description: string
	isShowWeightNotif?: boolean
	type?: string
	isShowModalEditCattlesQty?: boolean
}

const initialState: NotificationProps = {
	open: false,
	title: '',
	description: '',
	isShowWeightNotif: false,
	isShowModalEditCattlesQty: false,
	type: undefined,
}

export const notificationsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		notifyUser(state, action) {
			state.open = true
			state.title = action.payload.title
			state.description = action.payload.description
			state.isError = action.payload.isError
			state.isWarning = action.payload.isWarning
			state.type = action.payload.type
			state.handleClose = action.payload.handleClose
		},
		setShowWeightNotif(state, action) {
			state.isShowWeightNotif = action.payload.isShowWeightNotif
		},
		setShowModalEditCattlesQty(state, action) {
			state.isShowModalEditCattlesQty = action.payload.isShowModalEditCattlesQty
		},
		closeNotification(state) {
			state.open = false
		},
	},
})

export default notificationsSlice.reducer
export const {
	notifyUser,
	closeNotification,
	setShowWeightNotif,
	setShowModalEditCattlesQty,
} = notificationsSlice.actions
