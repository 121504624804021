import { Box } from '@mui/material'
import DigitizationHeader from 'components/cattles/digitizationPage/ui/DigitizationHeader'
import PicturesViewer from 'components/cattles/digitizationPage/ui/PicturesViewer'
import CustomTextField from 'components/shared/textFields/CustomTextField'
import useDigitizationInfo from 'hook/useDigitizationInfo'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppDispatch } from 'store/hooks'
import DropzoneAreaInput from 'ui/DropzoneAreaInput'
import { ButtonUI } from 'ui/buttons/ButtonUI'

import { useCallbackPrompt } from 'hook/useCallbackPrompt'
import { useParams } from 'react-router-dom'
import ExitWarningModal from 'ui/modal/ExitWarningModal'
import { TypographyPrimary14Bold } from 'ui/typography/TypographyPrimary14Bold'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import SameTagWarningCard from './shared/SameTagWarningCard'
import { submitCowFormInfo } from './utils'

const DigitizationPage = () => {
	const {
		currentInfoAboutCow,
		infoFromDigitizatedCow,
		dailyStatistics,
		errorMessages,
		isAvailableIdentified,
		isIdentificatedCow,
		cameraInfo,
		isNeedSaveTagPicture,
		isNeedSaveTagNumber,
		isEnabledButton,
		setErrorMessages,
		handleFiles,
	} = useDigitizationInfo()

	const dispatch = useAppDispatch()
	const isOpenModal = isNeedSaveTagNumber || isNeedSaveTagPicture
	const { showPrompt, confirmNavigation, cancelNavigation } =
		useCallbackPrompt(isOpenModal)
	const params = useParams()

	const cameraId = cameraInfo ? cameraInfo.id : +params.cameraId
	return (
		<Box sx={{ padding: '0 16px' }}>
			<DigitizationHeader
				isAvailableIdentified={isAvailableIdentified}
				identifiedObj={dailyStatistics?.identified_count}
			/>
			<Box
				mt={'24px'}
				display={'flex'}
				flexDirection={'row'}
				alignItems={'center'}
				gap={'8px'}
			>
				{cameraInfo && (
					<>
						{' '}
						<TypographyPrimary14Bold>
							{cameraInfo.title}
						</TypographyPrimary14Bold>
						<TypographySecondary14>
							{cameraInfo.milking_hall}
						</TypographySecondary14>
					</>
				)}
			</Box>
			<Box mt={'24px'}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						gap: '24px',
						'@media(max-width: 1200px)': {
							flexDirection: 'column-reverse',
						},
					}}
				>
					<Box
						sx={{
							width: '60%',
							'@media(max-width: 1200px)': {
								width: '100%',
							},
						}}
					>
						<PicturesViewer
							images={currentInfoAboutCow}
							isIdentificatedCow={isIdentificatedCow}
						/>
					</Box>
					<Box
						sx={{
							width: '40%',
							'@media(max-width: 1200px)': {
								width: '100%',
							},
						}}
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<DropzoneAreaInput
							currentFile={currentInfoAboutCow.tagImage}
							onFileChange={handleFiles}
						/>
						<CustomTextField
							key={'tagNumber'}
							label='Номер ушной бирки'
							value={
								currentInfoAboutCow.tagNumber
									? currentInfoAboutCow.tagNumber
									: ''
							}
							width={'100%'}
							fontWeight={600}
							adorment={
								infoFromDigitizatedCow &&
								!isNeedSaveTagPicture &&
								!isNeedSaveTagNumber
									? true
									: false
							}
							isIdentification={
								infoFromDigitizatedCow &&
								!isNeedSaveTagPicture &&
								!isNeedSaveTagNumber
									? true
									: false
							}
							withLabel={currentInfoAboutCow.tagNumber ? true : false}
							error={errorMessages !== '' ? true : false}
							helperText={errorMessages}
							onChange={e => {
								setErrorMessages('')
								handleFiles({ key: 'tagNumber', value: e.target.value })
							}}
						/>
						{isAvailableIdentified ? (
							<ButtonUI
								onClick={e => {
									e.preventDefault()
									submitCowFormInfo(
										isNeedSaveTagPicture,
										isNeedSaveTagNumber,
										infoFromDigitizatedCow.cow.id,
										currentInfoAboutCow.tagImage,
										currentInfoAboutCow.tagNumber,
										setErrorMessages,
										dispatch,
										cameraId,
										isIdentificatedCow
									)
								}}
								sx={{
									width: '100%',
									height: '56px',
									fontWeight: 600,
									fontSize: '14px',
									lineHeight: '17px',
									marginTop: '0px',
								}}
								disabled={!isEnabledButton}
							>
								{isIdentificatedCow &&
								!isNeedSaveTagPicture &&
								!isNeedSaveTagNumber
									? 'Подтвердить'
									: 'Сохранить'}
							</ButtonUI>
						) : (
							<SameTagWarningCard
								title={'Достигнут лимит оцифрованных голов'}
								description={'Доступен режим идентификации'}
							/>
						)}
						{errorMessages && currentInfoAboutCow.tagNumber && (
							<SameTagWarningCard
								title={
									'Вы уверены, что указанный номер совпадает с номером на бирке?'
								}
								onClick={() =>
									CattlesApiService.patchForceCowTagNumber(
										+currentInfoAboutCow.tagNumber,
										infoFromDigitizatedCow.cow.id
									).then(() => setErrorMessages(''))
								}
								descriptionButton={'Да'}
							/>
						)}
					</Box>
				</Box>
			</Box>
			<ExitWarningModal
				open={showPrompt}
				onClose={cancelNavigation}
				onSave={() => {
					if (isIdentificatedCow) {
						submitCowFormInfo(
							isNeedSaveTagPicture,
							isNeedSaveTagNumber,
							infoFromDigitizatedCow.cow.id,
							currentInfoAboutCow.tagImage,
							currentInfoAboutCow.tagNumber,
							setErrorMessages,
							dispatch,
							cameraId,
							isIdentificatedCow
						)
						confirmNavigation()
					} else {
						cancelNavigation()
					}
				}}
				onDismiss={confirmNavigation}
				title={
					isIdentificatedCow ? 'Сохранить изменения?' : 'Выйти без сохранения'
				}
				description={
					isIdentificatedCow ? null : 'Внесенные изменения будут утеряны.'
				}
				onSaveText={
					isIdentificatedCow ? 'Сохранить' : 'Вернуться к редактированию'
				}
				onDismissText={
					isIdentificatedCow ? 'Не сохранять' : 'Выйти без сохранения'
				}
			/>
		</Box>
	)
}

export default DigitizationPage
