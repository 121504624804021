import { Box } from '@mui/material'
import { ReactComponent as MenuIconCattles } from 'assets/icons/menu_cattles.svg'
import Skeleton from 'react-loading-skeleton'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import ProgressOfDigitization from '../shared/ProgressOfDigitization'

const DigitizationHeader = props => {
	const { identifiedObj, isAvailableIdentified } = props
	return (
		<CardStandart
			sx={{
				padding: '14px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: '24px',
			}}
		>
			<MenuIconCattles fill={COLORS.secondary} />
			<Box sx={{ width: '100%', position: 'relative' }}>
				<ProgressOfDigitization
					value={identifiedObj?.value ? identifiedObj.value : 0}
					total={identifiedObj?.out_of ? identifiedObj.out_of : 0}
					width={'100%'}
					progressColor={isAvailableIdentified ? COLORS.secondary : COLORS.main}
					backgroundColor={COLORS.inputBorder}
				/>

				<TypographySecondary
					sx={{ position: 'absolute', top: '10px', right: '0px' }}
				>
					{identifiedObj ? (
						`Оцифровано ${identifiedObj.value} из ${identifiedObj.out_of} доступных`
					) : (
						<Skeleton width={220} />
					)}
				</TypographySecondary>
			</Box>
		</CardStandart>
	)
}

export default DigitizationHeader
