import { CameraType, ICameraListItem } from 'models/IFrameInfo'
import axAPI from '../http'

export default class CameraApiService {
	static getCameraList = async (
		type: CameraType
	): Promise<ICameraListItem[]> => {
		const response = await axAPI.get(`/camera/list?type=${type}`)
		return response.data as ICameraListItem[]
	}
}
