import { Box, TableCellProps } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/cross_close_logo.svg'
import { morphForWords } from 'components/shared/processes'
import { ICowsList } from 'models/ICattlesData'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import CustomTableRow from 'ui/table/CustomTableRow'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type TypeProps = {
	cowsInfo: ICowsList[]
	removeCowInfoById: (id) => void
}

const TableBodyEditTrim = (props: TypeProps) => {
	const { cowsInfo, removeCowInfoById } = props
	const isOneCow = cowsInfo.length === 1
	const tableArray = cowsInfo.map(el => {
		const diffLastTrimMs = moment().diff(el.trim_last_dt)
		const diffLastTrim = moment(diffLastTrimMs).format('D')
		const obj = {
			rowKey: `bodyTrimRowId${el.id}`,
			height: '100%',
			cellItems: [
				{
					padding: '16px 8px 0px 0px',
					children: `${el.tag_id}`,
				},
				{
					padding: '16px 8px 0px 0px',
					children: `${el.group.title}`,
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box display={'flex'} flexDirection={'column'}>
							<TypographyPrimary>
								{el.limpness}{' '}
								<span style={{ color: COLORS.icon, fontSize: '12px' }}>
									{morphForWords(el.limpness, ['балл', 'балла', 'баллов'])}
								</span>
							</TypographyPrimary>
							<TypographySecondary>
								{el.limpness_length
									? el.limpness_length +
									  morphForWords(el.limpness_length, ['день', 'дня', 'дней'])
									: '-'}
							</TypographySecondary>
						</Box>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box display={'flex'} flexDirection={'column'}>
							{el.trim_last_dt ? (
								<>
									<TypographySecondary14>
										{moment(el.trim_last_dt).format('D.MM.YY')}
									</TypographySecondary14>
									<TypographySecondary14>
										{`${diffLastTrim} ${morphForWords(diffLastTrim, [
											'день',
											'дня',
											'дней',
										])}`}
									</TypographySecondary14>
								</>
							) : (
								<TypographySecondary14>Не назначено</TypographySecondary14>
							)}
						</Box>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box display={'flex'} flexDirection={'column'}>
							{el.trim_next_dt ? (
								<TypographyPrimary
									sx={{
										color: moment().isAfter(el.trim_next_dt)
											? COLORS.error
											: COLORS.black,
									}}
								>
									{moment(el.trim_next_dt).format('D.MM.YY')}
								</TypographyPrimary>
							) : (
								<TypographySecondary14>Не назначено</TypographySecondary14>
							)}
						</Box>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box display={'flex'} flexDirection={'column'}>
							{el.trim_responsible_last_name &&
							el.trim_responsible_first_name ? (
								<>
									<TypographyPrimary>
										{`${el.trim_responsible_last_name}. ${el.trim_responsible_first_name[0]}`}
									</TypographyPrimary>
									{el.trim_responsible_position && (
										<TypographySecondary>
											{el.trim_responsible_position}
										</TypographySecondary>
									)}
								</>
							) : (
								<TypographySecondary14>{'Не назначен'}</TypographySecondary14>
							)}
						</Box>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					align: 'right' as TableCellProps['align'],
					children: (
						<>
							<CloseIcon
								onClick={() => !isOneCow && removeCowInfoById(el.id)}
								style={{
									cursor: isOneCow ? 'auto' : 'pointer',
									stroke: isOneCow ? COLORS.inputBorder : COLORS.secondary,
								}}
								width={'24px'}
								height={'24px'}
							/>
						</>
					),
				},
			],
		}
		return obj
	})
	return (
		<>
			{tableArray.map(el => (
				<CustomTableRow
					key={el.rowKey}
					rowKey={el.rowKey}
					cellItems={el.cellItems}
					rowHeight={el.height}
				/>
			))}
		</>
	)
}

export default TableBodyEditTrim
