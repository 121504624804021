import { Box } from '@mui/material'
import { IGroupEntity } from '../../models/IGroup'

import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { yesterdayDate } from '../foodTable/ui/GetPoolCards'
import GroupSpecificConsuptionChart from './GroupSpecificConsuptionChart'
import GroupSpecificDeviationList from './GroupSpecificDeviationList'
import GroupSpecificDryIntake from './GroupSpecificDryIntake'
import GroupSpecificFeedPlan from './GroupSpecificFeedPlan'
import GroupSpecificHeadChart from './GroupSpecificHeadChart'
import GroupSpecificIndicators from './GroupSpecificIndicators'
import GroupSpecificInfo from './GroupSpecificInfo'
import GroupSpecificListOpen from './GroupSpecificListOpen'

const GroupSpecific = (props: IGroupEntity) => {
	return (
		<Box
			sx={{
				display: 'grid',
				width: '100%',
				height: '100%',
				gap: '24px',
			}}
		>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr',
					gap: '24px',
					'@media (min-width: 1100px)': {
						gridTemplateColumns: '0.7fr 0.3fr',
					},
				}}
			>
				<GroupSpecificInfo
					cattles_qty={props.cattles_qty}
					barntitle={props.barntitle}
					description={props.description}
					rationtitle={props.rationtitle}
					weight_per_head={props.weight_per_head}
					reachable={props.foodtable.reachable_percent}
					unreachable={props.foodtable.unreachable_percent}
					total_percent={props.foodtable.total_percent}
					lastDistribution={props.last_distribution_dt}
					lastNudge={props.last_nudge_dt}
					noData={props.foodtable.no_data}
				/>
				<CardStandart sx={{ padding: '24px' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<TypographyHeader>Показатели</TypographyHeader>
						<TypographySecondary14>
							Вчера, {yesterdayDate}
						</TypographySecondary14>
					</Box>
					<Box
						sx={{
							height: '80%',
							marginTop: '24px',
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gridTemplateRows: '1fr 1fr',
							gap: '16px',
							justifyContent: 'space-between',
						}}
					>
						<GroupSpecificIndicators
							bulk='Среднее ПСВ'
							changeValue={1}
							count={props.food_dry_per_head}
							ratio='кг/голову'
						/>

						<GroupSpecificIndicators
							bulk='КС пустой'
							changeValue={1}
							count={props.empty_food_table_duration}
							ratio='мин'
						/>

						<GroupSpecificIndicators
							bulk='Роздано корма'
							changeValue={1}
							count={props.previous_distribution_weight}
							ratio='кг'
						/>

						<GroupSpecificIndicators
							bulk='Остаток корма'
							changeValue={1}
							count={props.previous_cleaning_weight}
							ratio='кг'
						/>
					</Box>
				</CardStandart>
			</Box>
			<GroupSpecificListOpen
				group_title={props.title}
				id={props.id}
				title='Состояние кормового стола'
			/>

			<GroupSpecificDeviationList id={props.id} />

			<GroupSpecificFeedPlan id={props.id} title={props.title} />

			<GroupSpecificHeadChart groupkey={props.groupkey} group_id={props.id} />

			<GroupSpecificDryIntake groupkey={props.groupkey} group_id={props.id} />

			<GroupSpecificConsuptionChart group_id={props.id} />
		</Box>
	)
}

export default GroupSpecific
