import { Box, InputAdornment, Paper } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { ReactComponent as NextIcon } from 'assets/arrow_close_logo.svg'
import { ReactComponent as CrossCloseLogo } from 'assets/cross_close_logo.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { ReactComponent as PlusIcon } from 'assets/plan_edit_plus_logo.svg'
import { ReactComponent as SearchIcon } from 'assets/search.svg'
import { forwardRef, useEffect, useState } from 'react'
import { COLORS } from 'styles/colors'
import Preloader from 'ui/Preloader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

const icon = <CheckBox />
const checkedIcon = <CheckBoxActive />
type ArrayType = {
	id?: number
	title: string
	description?: string
	group?: string
}
type AutocompletePropsType = {
	availableList?: ArrayType[]
	limitTags?: number
	label: string
	widthForInput: string
	isError?: boolean
	isCheckbox?: boolean
	onClick?: (type) => void
	selectedValues?: number[]
	onClickListButton?: () => void
	textDescriptionForClickButton?: string
	needChip?: boolean
	isDisable?: boolean
	loading?: boolean
	bgInputColor?: string
}

export default function AutocompleteInput(props: AutocompletePropsType) {
	const {
		availableList,
		limitTags,
		label,
		widthForInput,
		isError,
		isCheckbox,
		onClickListButton,
		textDescriptionForClickButton,
		onClick,
		selectedValues,
		needChip,
		isDisable,
		loading,
		bgInputColor = 'transparent',
	} = props
	const [value, setValue] = useState([])
	const [inputValue, setInputValue] = useState('')

	useEffect(() => {
		if (availableList) {
			setValue(
				availableList.filter(item => value.some(val => val.id === item?.id))
			)
		}
	}, [availableList])

	useEffect(() => {
		const state = availableList.filter(item =>
			selectedValues.includes(item?.id)
		)
		setValue(state)
	}, [selectedValues])
	const filterValues = (id: number | undefined) => {
		setValue(prevState => prevState.filter(el => el.id !== id))
		onClick(id)
	}
	const ListboxComponent = forwardRef<HTMLUListElement, any>(
		function ListboxComponent(props, ref) {
			const { children, ...other } = props

			return (
				<Box
					component='ul'
					{...other}
					ref={ref}
					sx={{ height: '200px', position: 'relative', overflow: 'auto' }}
				>
					{children}
					{onClickListButton && (
						<Box
							onClick={() => onClickListButton()}
							sx={{
								zIndex: '14000',
								position: 'fixed',
								bottom: '10px',
								left: '10px',
								width: '90%',
								backdropFilter: 'blur(2px)',
								borderRadius: '8px',
								background: 'rgba(255, 255, 255, 0.70)',
								display: 'flex',
								flexDirection: 'row',
								gap: '8px',
								alignItems: 'center',
								cursor: 'pointer',
							}}
						>
							<PlusIcon height='24px' width='24px' stroke={COLORS.secondary} />
							<TypographySecondary14>
								{textDescriptionForClickButton}
							</TypographySecondary14>
						</Box>
					)}
				</Box>
			)
		}
	)
	const sx = {
		width: widthForInput,
		backgroundColor: bgInputColor,
		borderRadius: '8px',
		'& label.Mui-focused': {
			paddingLeft:
				value?.length !== 0 || inputValue?.length !== 0 ? '0px' : '25px',
			color: `${COLORS.hoverInputBorder}`,
			fontSize: '14px',
		},
		'.MuiAutocomplete-inputRoot': {
			opacity: !isDisable ? 1 : 0.5,
		},

		'& .MuiInputBase-input': {
			paddingLeft:
				value?.length !== 0 || inputValue?.length !== 0 ? '0px' : '25px',
			fontSize: '14px',
		},
		'& .MuiInputLabel-outlined': {
			paddingLeft:
				value?.length !== 0 || inputValue?.length !== 0 ? '0px' : '25px',
			fontSize: '14px',
		},
		'& .MuiOutlinedInput-root': {
			fontSize: '14px',
			borderRadius: '8px',
			borderColor: isError ? COLORS.deviationFont : COLORS.inputBorder,
			'&.Mui-focused fieldset': {
				border: `1px solid ${COLORS.inputBorder}`,
				color: COLORS.main,
				boxShadow: '0px 4px 16px 0px #00000014',
			},
			'&:hover fieldset': {
				opacity: !isDisable ? 1 : 0.5,
				boxShadow: !isDisable ? '0px 4px 16px 0px #00000014' : 'none',
				border: !isDisable
					? `1px solid ${COLORS.inputBorder}`
					: `1px solid ${COLORS.icon}`,
			},
		},
		'&.MuiOutlinedInput-input': {
			borderColor: isError ? COLORS.deviationFont : COLORS.inputBorder,
		},
	}
	return (
		<>
			<Autocomplete
				sx={sx}
				multiple
				disabled={isDisable}
				disableClearable
				isOptionEqualToValue={(option, value) => option.id === value.id}
				fullWidth
				loading={loading}
				loadingText={
					<Box sx={{ position: 'absolute', top: '40%', left: '120px' }}>
						<Preloader />
					</Box>
				}
				limitTags={limitTags}
				getLimitTagsText={more => `...`}
				noOptionsText='Результат поиска: 0'
				value={value}
				onKeyDown={event => {
					if (event.key === 'Backspace' && value?.length > 0) {
						const lastElementId = value[value?.length - 1].id
						onClick(lastElementId)
					}
				}}
				onChange={(event, newValue) => {
					setValue(newValue)
				}}
				ListboxComponent={ListboxComponent}
				inputValue={inputValue}
				PaperComponent={props => (
					<Box
						sx={{
							marginTop: '4px',
							borderRadius: '8px',
							border: `1px solid ${COLORS.inputBorder}`,
							boxShadow: 'none',
							'& .MuiAutocomplete-noOptions': {
								fontSize: '12px',
								height: '200px',
							},
						}}
					>
						<Paper
							{...props}
							style={{
								borderRadius: '8px',
								boxShadow: '0px 4px 16px 0px #00000014',
								height: '200px',
							}}
						/>
					</Box>
				)}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue)
				}}
				options={availableList?.map(option => {
					return {
						group: option?.group,
						...option,
					}
				})}
				groupBy={option => option?.group}
				disableCloseOnSelect={true}
				getOptionLabel={option => option?.title}
				renderOption={(props, option, { selected }) => {
					return (
						<li
							{...props}
							key={option.id ? option.id : option.title}
							onClick={() => onClick && onClick(option.id)}
							style={{
								width: widthForInput,
								padding: '4px 16px',
								backgroundColor: selected
									? COLORS.mainSecondaryOpacity
									: 'transparent',
							}}
							onMouseEnter={e =>
								(e.currentTarget.style.backgroundColor =
									COLORS.mainSecondaryOpacity)
							}
							onMouseLeave={e =>
								(e.currentTarget.style.backgroundColor = selected
									? COLORS.mainSecondaryOpacity
									: 'transparent')
							}
						>
							{isCheckbox && (
								<Checkbox
									icon={icon}
									disableRipple
									checkedIcon={checkedIcon}
									style={{ padding: '0px 10px 0px 0px', margin: '0px' }}
									checked={selected}
								/>
							)}
							<TypographyPrimary>
								{option.title && option.title}
							</TypographyPrimary>
							{option.description && (
								<TypographySecondary>{option.description}</TypographySecondary>
							)}
						</li>
					)
				}}
				renderGroup={params => {
					if (params.group) {
						return (
							<Box key={params.key}>
								<Box
									sx={{
										position: 'sticky',
										top: '-8px',
										padding: '8px 16px',
										backgroundColor: '#fff',
										zIndex: 2,
									}}
								>
									<TypographySecondary>{params.group}</TypographySecondary>
								</Box>
								{params.children}
							</Box>
						)
					} else {
						return <Box key={params.key}>{params.children}</Box>
					}
				}}
				renderTags={values =>
					values.map((value, index) => (
						<TypographyPrimary key={index} sx={{ whiteSpace: 'nowrap' }}>
							{value.title + ','}
						</TypographyPrimary>
					))
				}
				popupIcon={<NextIcon stroke={isError ? COLORS.error : COLORS.icon} />}
				renderInput={params => (
					<TextField
						error={isError}
						sx={sx}
						{...params}
						label={label}
						InputLabelProps={{
							...params.InputLabelProps,
							shrink: value?.length > 0 || inputValue?.length > 0,
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<>
									<InputAdornment
										position='start'
										sx={{ padding: '0px', margin: '0px' }}
									>
										<SearchIcon stroke={isError ? COLORS.error : COLORS.icon} />
									</InputAdornment>
									{params.InputProps.startAdornment}
								</>
							),
						}}
					/>
				)}
			/>
			{needChip && (
				<Box
					width={widthForInput}
					display={'flex'}
					flexDirection={'row'}
					gap={'4px'}
					flexWrap={'wrap'}
					mt={'16px'}
				>
					{value.map(el => (
						<Box
							key={el.id}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '6px',
								width: 'max-content',
								padding: '8px 16px',
								backgroundColor: COLORS.background,
								borderRadius: '12px',
								'&:hover': {
									backgroundColor: COLORS.mainSecondaryOpacity,
									'& > svg': {
										stroke: COLORS.main + '!important',
									},
								},
							}}
						>
							<TypographyPrimary>{el.title}</TypographyPrimary>
							<CrossCloseLogo
								style={{
									cursor: 'pointer',
									width: '16px',
									height: '16px',
									stroke: COLORS.secondary,
								}}
								onClick={() => filterValues(el.id)}
							/>
						</Box>
					))}
				</Box>
			)}
		</>
	)
}
