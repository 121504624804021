import {
	Box,
	Checkbox,
	InputAdornment,
	OutlinedInput,
	ThemeProvider,
	useTheme,
} from '@mui/material'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { morphForWords, roundingNumber } from 'components/shared/processes'
import React from 'react'
import { COLORS } from 'styles/colors'
import { customTheme } from 'ui/buttons/ButtonStyle'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ReactComponent as CheckBox } from '../../../../../assets/icons/checkBox.svg'
import { TreatmentStatus } from '../models'
import { treatmentsStatuses } from '../processes'

const icon = <CheckBox />
const checkedIcon = <CheckBoxActive />
const TreatmentContentBlock = props => {
	const {
		toggleFilter,
		treatmentStatus,
		availableTreatmentValues,
		handleDayChange,
	} = props
	const isDisabledTreatmentStatusDateInput = !treatmentStatus.statuses.includes(
		TreatmentStatus.Treated
	)
	const outerTheme = useTheme()

	return (
		<>
			<ul>
				{treatmentsStatuses.map(status => (
					<li
						onClick={() => toggleFilter('treatmentStatus', status.key)}
						key={status.id}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							padding: '6px 0px',
						}}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={treatmentStatus.statuses.includes(status.key)}
						/>
						<TypographyPrimary sx={{ cursor: 'pointer' }}>
							{status.title}
						</TypographyPrimary>
					</li>
				))}
			</ul>
			<TypographyPrimary
				sx={{
					color: isDisabledTreatmentStatusDateInput
						? COLORS.secondaryFont
						: 'none',
				}}
			>
				Продолжительность, дни
			</TypographyPrimary>
			<ThemeProvider theme={customTheme(outerTheme)}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'12px'}
					marginTop={'16px'}
					flexWrap={'wrap'}
				>
					<OutlinedInput
						label='none'
						notched={false}
						disabled={isDisabledTreatmentStatusDateInput}
						type='number'
						error={
							treatmentStatus.startOf < 0 ||
							treatmentStatus.startOf > treatmentStatus.endOf
								? true
								: false
						}
						placeholder={
							availableTreatmentValues?.startOf
								? `${availableTreatmentValues.startOf} ${morphForWords(
										availableTreatmentValues.startOf,
										['день', 'дня', 'дней']
								  )}`
								: 'Дни'
						}
						value={
							treatmentStatus.startOf
								? roundingNumber(treatmentStatus.startOf, 0)
								: ''
						}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(treatmentStatus.startOf, 0)?.toString()
											.length <= 3
											? '25px'
											: roundingNumber(treatmentStatus.startOf, 0)?.toString()
													.length <= 5
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{treatmentStatus.startOf ? (
									<TypographySecondary>от</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'treatmentStatus', 'startOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>

					<TypographySecondary>-</TypographySecondary>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						disabled={isDisabledTreatmentStatusDateInput}
						error={
							treatmentStatus.endOf < 0 ||
							treatmentStatus.endOf < treatmentStatus.startOf
								? true
								: false
						}
						placeholder={
							availableTreatmentValues?.endOf
								? `${availableTreatmentValues.endOf} ${morphForWords(
										availableTreatmentValues.endOf,
										['день', 'дня', 'дней']
								  )}`
								: 'Дни'
						}
						value={
							treatmentStatus.endOf
								? roundingNumber(treatmentStatus.endOf, 0)
								: ''
						}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(treatmentStatus.endOf, 0)?.toString()
											.length <= 3
											? '25px'
											: roundingNumber(treatmentStatus.endOf, 0)?.toString()
													.length <= 5
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{treatmentStatus.endOf ? (
									<TypographySecondary>до</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'treatmentStatus', 'endOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>
				</Box>
			</ThemeProvider>
		</>
	)
}

export default TreatmentContentBlock
