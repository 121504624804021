import { useNavigate } from 'react-router-dom'

import { Box, Divider, Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import { experimentalStyled as styled } from '@mui/material/styles'

import { CardProps } from '../../../models'

import { EDeviationState } from 'models/EDeviation'
import { useEffect, useState } from 'react'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import axAPI from '../../../http'
import { CardStandart } from '../../../ui/CardStandart'
import { TypographySecondary14 } from '../../../ui/typography/TypographySecondary14'
import { firstDayHandler } from '../../shared/processes'

const SimpleCardItem = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	borderRadius: 0,
	boxShadow: '0 0 0 0',
	padding: 0,
	textAlign: 'center',
	color: theme.palette.text.secondary,
	height: '40px',
}))

const SimpleCard = (props: CardProps) => {
	const primaryDateState = firstDayHandler()
	const dateRange = {
		startDate: primaryDateState[0],
		endDate: primaryDateState[1],
	}
	const navigate = useNavigate()
	const [deviationsList, setDeviationList] = useState([])
	const [fetchingData, setFetchingData] = useState(true)

	useEffect(() => {
		setFetchingData(true)
		axAPI
			.get(
				`captured-deviations?start=${dateRange.startDate}&end=${
					dateRange.endDate + 86400000
				}`
			)
			.then(response => {
				response.data.length !== 0
					? setDeviationList(response.data)
					: setDeviationList([])
			})
			.finally(() => setFetchingData(false))
	}, [])
	if (fetchingData) {
		return <SkeletonEmptyCard height={'244px'} needTitle heightTitle={'24px'} />
	}

	const rationNumber = deviationsList.filter(value => {
		return value.type === EDeviationState.Ration
	}).length
	const planNumber = deviationsList.filter(value => {
		return (
			value.type === EDeviationState.Feedplan ||
			value.type === EDeviationState.FeedplanWeight
		)
	}).length
	const cameraNumber = deviationsList.filter(value => {
		return (
			value.type === EDeviationState.Camera ||
			value.type === EDeviationState.CameraIssue
		)
	}).length
	return (
		<CardStandart
			onClick={() => navigate('/feed/deviations')}
			sx={{
				padding: '24px',
				cursor: 'pointer',
				width: '100%',
				height: '324px',
			}}
		>
			<TypographyHeader sx={{ marginBottom: '24px' }}>
				{props.title}
			</TypographyHeader>
			<Grid container direction='column' spacing={2}>
				<Grid item>
					<SimpleCardItem>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<TypographyPrimary>Рационы</TypographyPrimary>
							<TypographySecondary14 sx={{ fontWeight: '500' }}>
								{rationNumber}
							</TypographySecondary14>
						</Box>
						<Divider />
					</SimpleCardItem>

					<SimpleCardItem>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<TypographyPrimary>План кормления</TypographyPrimary>
							<TypographySecondary14 sx={{ fontWeight: '500' }}>
								{planNumber}
							</TypographySecondary14>
						</Box>
						<Divider />
					</SimpleCardItem>

					<SimpleCardItem>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<TypographyPrimary>Камеры и освещение</TypographyPrimary>
							<TypographySecondary14 sx={{ fontWeight: '500' }}>
								{cameraNumber}
							</TypographySecondary14>
						</Box>
						<Divider />
					</SimpleCardItem>
				</Grid>
			</Grid>
		</CardStandart>
	)
}

export default SimpleCard
