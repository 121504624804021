import Box from '@mui/material/Box/Box'
import moment from 'moment'
import CattlesApiService from 'services/cattlesApiServices'
import { ReactComponent as BarchartInfoLogo } from '../../assets/barchart_info_logo.svg'
import { useAppDispatch } from '../../store/hooks'
import {
	notifyUser,
	setShowModalEditCattlesQty,
	setShowWeightNotif,
} from '../../store/notificationsSlice'
import { COLORS } from '../../styles/colors'
import { CardStandart } from '../../ui/CardStandart'
import CustomButton from '../../ui/buttons/CustomButton'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

const GroupHeadPopup = props => {
	const dispatch = useAppDispatch()

	const noChangesNeeded = () => {
		CattlesApiService.postNoChangedNeededForCattlesQty().then(resonse => {
			if (resonse.status === 201) {
				dispatch(setShowWeightNotif({ isShowWeightNotif: false }))
				dispatch(
					notifyUser({
						title: 'Данные сохранены успешно',
						description: 'Вес раздач остался без изменений.',
						isError: false,
					})
				)
			}
		})
	}

	return (
		<>
			<CardStandart
				sx={{
					padding: '16px 24px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '16px',
						}}
					>
						<BarchartInfoLogo style={{ color: COLORS.icon }} />
						<TypographyPrimary>
							Вес раздач на{' '}
							<span style={{ color: COLORS.main }}>
								Сегодня, {moment().format('DD.MM.YYYY')}
							</span>{' '}
							рассчитан по текущему количеству голов в группах.
							<br />
							Внести изменения?
						</TypographyPrimary>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
						<CustomButton
							title={'Изменить'}
							onClick={() =>
								dispatch(
									setShowModalEditCattlesQty({
										isShowModalEditCattlesQty: true,
									})
								)
							}
						/>
						<CustomButton
							title={'Оставить без изменений'}
							onClick={noChangesNeeded}
						/>
					</Box>
				</Box>
			</CardStandart>
		</>
	)
}

export default GroupHeadPopup
