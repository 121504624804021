import { Box } from '@mui/material'
import { ReactComponent as InfoLogo } from 'assets/barchart_info_logo.svg'
import { COLORS } from 'styles/colors'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

const HeaderCowQty = ({ cowQty }) => {
	return (
		<>
			<TypographyHeader>Информация по обрезке копыт</TypographyHeader>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
					marginTop: '16px',
				}}
			>
				<InfoLogo
					style={{ color: COLORS.icon, width: '16px', height: '16px' }}
				/>
				<TypographySecondary14>
					При внесении изменений автоматически будет произведен перерасчет
					последующих дат обрезок.
				</TypographySecondary14>
			</Box>
			<TypographySecondary14 sx={{ marginTop: '24px' }}>
				Количество голов: <span style={{ color: COLORS.black }}>{cowQty}</span>
			</TypographySecondary14>
		</>
	)
}

export default HeaderCowQty
