export interface INotification {
	content_type: number
	created_dt: number
	id: number
	object_id: number
	status: string
	text: string
	title: string
	groups: INotificationGroups[]
}

export interface INotificationGroups {
	id: number
	title: string
}

export enum NotificationsTypes {
	DailyChangedWeight = 'dailyChangedWeight',
}
