import { Box, Collapse, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { useEffect } from 'react'

import { fetchFeedPlanData } from '../../store/feedPlanSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import GroupHeadPopup from '../shared/GroupHeadPopup'
import CowCounter from './ui/CowCounter'
import GetPoolCards from './ui/GetPoolCards'
import GetCardItem from './ui/PlanCardWidjet'
import SimpleCard from './ui/SimpleCard'

import { Outlet } from 'react-router-dom'
import { selectPermissions } from '../../store/authSlice'
import { fontTheme } from '../../styles/font'
import FoodTableInfo from './ui/FoodTableInfo'

const theme = fontTheme

const FeedGroupsPage = () => {
	const dispatch = useAppDispatch()
	const permissions = useAppSelector(selectPermissions)
	useEffect(() => {
		dispatch(fetchFeedPlanData({}))
	}, [])

	const { isShowWeightNotif } = useAppSelector(
		store => store.notificationsSlice
	)

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<CowCounter />
			<Box
				sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '32px' }}
			>
				{permissions && permissions.can_edit_cattle_groups && (
					<Collapse in={isShowWeightNotif} timeout='auto' unmountOnExit>
						<GroupHeadPopup />
					</Collapse>
				)}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: '24px',
						marginBottom: '24px',
						marginTop: '24px',
						'@media (max-width: 1200px)': {
							flexDirection: 'column',
						},
					}}
				>
					<GetPoolCards />
					<GetCardItem title='План кормления на день' />
					<SimpleCard title='Обнаруженные отклонения' />
				</Box>
				<FoodTableInfo />
			</Box>
			<Outlet />
		</ThemeProvider>
	)
}

export default FeedGroupsPage
