import { Box } from '@mui/material'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import CustomButton from 'ui/buttons/CustomButton'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { ReactComponent as BarchartInfoLogo } from '../../../../assets/barchart_info_logo.svg'

const SameTagWarningCard = props => {
	const { title, onClick, descriptionButton, description } = props
	return (
		<CardStandart
			sx={{
				padding: '16px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<span>
					<BarchartInfoLogo
						style={{ color: COLORS.health, width: '26px', height: '26px' }}
					/>
				</span>
				<Box>
					<TypographyPrimary16Bold>{title}</TypographyPrimary16Bold>
					{description && <TypographyPrimary>{description}</TypographyPrimary>}
				</Box>
			</Box>
			{descriptionButton && (
				<CustomButton title={descriptionButton} onClick={onClick} />
			)}
		</CardStandart>
	)
}

export default SameTagWarningCard
