import { Box, Popover } from '@mui/material'
import ResponsiblePersonsBlock from 'components/cattles/trim/shared/filterContent/ResponsiblePersonsBlock'
import TrimContentBlock from 'components/cattles/trim/shared/filterContent/TrimContentBlock'
import React, { useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { COLORS } from 'styles/colors'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import CustomButton from 'ui/buttons/CustomButton'
import { TypographyHeader14 } from 'ui/typography/TypographyHeader14'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ReactComponent as CrossCloseLogo } from '../../../../../assets/cross_close_logo.svg'
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter-icon.svg'
import CowAgeContentBlock from './CowAgeContentBlock'
import DiagnoseContentBlock from './DiagnoseContentBlock'
import ExaminersContentBlock from './ExaminersContentBlock'
import FatnessContentBlock from './FatnessContentBlock'
import GroupsContentBlock from './GroupsContentBlock'
import InspectionContentBlock from './InspectionContentBlock'
import LactationContentBlock from './LactationContentBlock'
import LamenessContentBlock from './LamenessContentBlock'
import TreatmentContentBlock from './TreatmentContentBlock'

const FilterContent = props => {
	const {
		activeFilters,
		availableFilters,
		setActiveFilters,
		openFilter,
		setOpenFilter,
		toggleFilter,
		handleDayChange,
		returnInitialProps,
		turnOnFilters,
		titleForFilter,
		isEqualFilter,
		isEqualLamenessFilter,
		isEqualTreatmentFilter,
		isEqualInspectionFilter,
		isEqualLactationFilter,
		isEqualCowAgeFilter,
		isEqualGroupsFilter,
		isEqualFatnessFilter,
		isEqualExaminersFilter,
		isEqualDiagnosisFilter,
		isEqualTrimFilter,
		isEqualRespPersonsFilter,
	} = props

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [selectedCase, setSelectedCase] = useState('lameness')
	const [cowsCount, setCowsCount] = useState<number>(0)

	const handleContentClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
		setOpenFilter(prev => !prev)
	}

	useEffect(() => {
		if (openFilter) {
			CattlesApiService.getCowsCount(activeFilters).then(res => {
				setCowsCount(res.data)
			})
		}
	}, [openFilter, activeFilters])
	const renderContent = () => {
		switch (selectedCase) {
			case 'lameness':
				return (
					<LamenessContentBlock
						toggleFilter={toggleFilter}
						availableLamenessValues={availableFilters?.lameness}
						lameness={activeFilters?.lameness}
						handleDayChange={handleDayChange}
					/>
				)
			case 'treatmentStatus':
				return (
					<TreatmentContentBlock
						toggleFilter={toggleFilter}
						availableTreatmentValues={availableFilters?.treatmentStatus}
						treatmentStatus={activeFilters?.treatmentStatus}
						handleDayChange={handleDayChange}
					/>
				)
			case 'inspectionStatus':
				return (
					<InspectionContentBlock
						toggleFilter={toggleFilter}
						inspectionStatus={activeFilters?.inspectionStatus}
						setActiveFilters={setActiveFilters}
					/>
				)
			case 'diagnosis':
				return (
					<DiagnoseContentBlock
						setActiveFilters={setActiveFilters}
						diagnoses={activeFilters?.diagnoses}
					/>
				)
			case 'lactation':
				return (
					<LactationContentBlock
						toggleFilter={toggleFilter}
						lactation={activeFilters?.lactation}
						setActiveFilters={setActiveFilters}
						handleDayChange={handleDayChange}
						turnOnFilters={turnOnFilters}
						selectedCase={selectedCase}
					/>
				)
			case 'groups':
				return (
					<GroupsContentBlock
						setActiveFilters={setActiveFilters}
						groups={activeFilters?.groups}
					/>
				)
			case 'cowAge':
				return (
					<CowAgeContentBlock
						availableAgeValues={availableFilters?.cowAge}
						handleDayChange={handleDayChange}
						cowAge={activeFilters?.cowAge}
					/>
				)
			case 'fatness':
				return (
					<FatnessContentBlock
						setActiveFilters={setActiveFilters}
						fatness={activeFilters?.fatness}
					/>
				)

			case 'examiners':
				return (
					<ExaminersContentBlock
						setActiveFilters={setActiveFilters}
						examiners={activeFilters?.examiners}
					/>
				)
			case 'lastTrim':
				return (
					<TrimContentBlock
						availableLastTrimValues={availableFilters?.lastTrim}
						lastTrim={activeFilters?.lastTrim}
						handleDayChange={handleDayChange}
					/>
				)
			case 'responsiblePersons':
				return (
					<ResponsiblePersonsBlock
						setActiveFilters={setActiveFilters}
						responsiblePersons={activeFilters?.responsiblePersons}
					/>
				)
		}
	}

	return (
		<>
			<Box sx={{ position: 'relative' }}>
				{!isEqualFilter && (
					<TypographySecondary
						onClick={() => returnInitialProps('all')}
						sx={{
							whiteSpace: 'nowrap',
							cursor: 'pointer',
							position: 'absolute',
							top: '10px',
							left: '-100px',
						}}
					>
						Сбросить всё
					</TypographySecondary>
				)}
				<CustomButton
					title={'Фильтр'}
					onClick={handleContentClick}
					iconElement={
						<Box sx={{ position: 'relative' }}>
							<FilterIcon />
							{!isEqualFilter && (
								<Box
									component={'span'}
									sx={{
										position: 'absolute',
										width: '10px',
										height: '10px',
										borderRadius: '50%',
										backgroundColor: COLORS.health,
										bottom: '7px',
										right: '-4px',
									}}
								></Box>
							)}
						</Box>
					}
					colors={[COLORS.main, '#fff']}
				/>
			</Box>
			<Popover
				open={openFilter}
				onClose={() => setOpenFilter(prev => !prev)}
				anchorEl={anchorEl}
				transitionDuration={200}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: -8 }}
				sx={{
					'.MuiPopover-paper': {
						width: '40vw',
						boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
						padding: '16px 24px',
						borderRadius: '8px',
					},
				}}
			>
				<Box
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<TypographyPrimary16Bold>Фильтр</TypographyPrimary16Bold>
					<CrossCloseLogo
						style={{ cursor: 'pointer', stroke: COLORS.icon }}
						onClick={() => setOpenFilter(prev => !prev)}
					/>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					height={'387px'}
					width={'100%'}
					marginTop={'24px'}
				>
					<ul
						style={{
							listStyleType: 'none',
							minWidth: '180px',
							borderRight: `1px solid ${COLORS.inputBorder}`,
						}}
					>
						{titleForFilter?.map(el => (
							<li
								key={el.id}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									padding: '7px 0px',
									flexWrap: 'wrap',
								}}
							>
								<TypographyPrimary
									onClick={() => setSelectedCase(el.key)}
									sx={{
										color: selectedCase === el.key ? COLORS.main : 'none',
										position: 'relative',
										cursor: 'pointer',
									}}
								>
									{el.title}
									{selectedCase === el.key && (
										<Box
											component='span'
											sx={{
												position: 'absolute',
												width: '10px',
												height: '10px',
												borderRadius: '50%',
												backgroundColor: COLORS.health,
												top: '10%',
												right: '-12px',
											}}
										/>
									)}
								</TypographyPrimary>

								{((el.key === 'lameness' && !isEqualLamenessFilter) ||
									(el.key === 'treatmentStatus' && !isEqualTreatmentFilter) ||
									(el.key === 'inspectionStatus' && !isEqualInspectionFilter) ||
									(el.key === 'cowAge' && !isEqualCowAgeFilter) ||
									(el.key === 'groups' && !isEqualGroupsFilter) ||
									(el.key === 'lactation' && !isEqualLactationFilter) ||
									(el.key === 'fatness' && !isEqualFatnessFilter) ||
									(el.key === 'examiners' && !isEqualExaminersFilter) ||
									(el.key === 'lastTrim' && !isEqualTrimFilter) ||
									(el.key === 'responsiblePersons' &&
										!isEqualRespPersonsFilter) ||
									(el.key === 'diagnosis' && !isEqualDiagnosisFilter)) && (
									<CrossCloseLogo
										style={{
											cursor: 'pointer',
											width: '16px',
											height: '12px',
											marginRight: '8px',
										}}
										stroke={COLORS.secondary}
										onClick={() => returnInitialProps(el.key)}
									/>
								)}
							</li>
						))}
					</ul>
					<Box sx={{ marginLeft: '24px', width: '75%' }}>
						<TypographyHeader14 sx={{ marginBottom: '16px' }}>
							{titleForFilter?.find(el => el.key === selectedCase).title}
						</TypographyHeader14>
						{renderContent()}
					</Box>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'flex-end'}
					justifyContent={'flex-end'}
				>
					<TypographySecondary>Результат: {cowsCount}</TypographySecondary>
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						justifyContent={'flex-end'}
						width={'100%'}
						mt={'16px'}
						gap={'16px'}
					>
						{!isEqualFilter && (
							<TypographySecondary
								onClick={() => returnInitialProps('all')}
								sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
							>
								Сбросить всё
							</TypographySecondary>
						)}
						<ButtonUI
							sx={{
								marginTop: '0px',
								width: '400px',
								height: '56px',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '17px',
							}}
							onClick={() => setOpenFilter(false)}
						>
							Сохранить
						</ButtonUI>
					</Box>
				</Box>
			</Popover>
		</>
	)
}

export default FilterContent
