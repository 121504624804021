import { PlanEventTypeEnum } from '.'

export enum PlanCardFlagsEnum {
	DONE = 'done',
	NEEDDATA = 'need_data',
	DISCREPANCIES = 'discrepancies',
}

export type PlanCardProps = {
	title: string
	time: string
	metaFlags: PlanCardFlagsEnum[]
	uniqueKey?: string
	feedPlanDate: Date
	groupsTitle: string
	deviationMinutes: number
	ration?: string
	cardType: PlanEventTypeEnum
	isFutureDate: boolean
	componentTimeType: string
	isCurrentDay?: boolean
}

export enum DateRestriction {
	PAST = 'past',
	FUTURE = 'future',
}
